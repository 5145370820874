const items = [
  {
    title: 'Taksim Square',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla felis, ultricies at morbi urna, montes, auctor. Duis eget facilisi sed sapien. Erat massa felis at praesent pellentesque. Magna proin dolor at ornare et lorem rhoncus. Dolor egestas et magna id congue at suspendisse imperdiet auctor. Rutrum platea lacus viverra at ultrices lectus amet, morbi. Fermentum at vitae vestibulum ipsum mi viverra. ',
    aspectRatio: '3:2',
  },
  {
    title: 'Grand Bazaar',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla felis, ultricies at morbi urna, montes, auctor. Duis eget facilisi sed sapien. Erat massa felis at praesent pellentesque. Magna proin dolor at ornare et lorem rhoncus. Dolor egestas et magna id congue at suspendisse imperdiet auctor. Rutrum platea lacus viverra at ultrices lectus amet, morbi. Fermentum at vitae vestibulum ipsum mi viverra. ',
    aspectRatio: '4:3',
  },
  {
    title: 'Hagia Sophia',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla felis, ultricies at morbi urna, montes, auctor. Duis eget facilisi sed sapien. Erat massa felis at praesent pellentesque. Magna proin dolor at ornare et lorem rhoncus. Dolor egestas et magna id congue at suspendisse imperdiet auctor. Rutrum platea lacus viverra at ultrices lectus amet, morbi. Fermentum at vitae vestibulum ipsum mi viverra. ',
  },
];

const image = {
  _type: 'image',
  asset: {
    _type: 'sanity.imageAsset',
    _id: 'image-fdc50268a297658b1fdc3b206c2853642d683d51-1996x3000-jpg',
    assetId: 'fdc50268a297658b1fdc3b206c2853642d683d51',
    path: 'images/nxpteyfv/media-studio/fdc50268a297658b1fdc3b206c2853642d683d51-1996x3000.jpg',
    url: 'https://cdn.sanity.io/images/nxpteyfv/media-studio/fdc50268a297658b1fdc3b206c2853642d683d51-1996x3000.jpg',
  },
  crop: null,
  hotspot: null,
};

const fullWidthImageItem = items.map((item) => ({
  ...item,
  image: image,
  imageCaption: 'Flower Market in Merida, CN',
}));

const dualImageItem = items.map((item) => ({
  ...item,
  image: image,
  imageCaption: 'Flower Market in Merida, CN',
  image2: image,
  imageCaption2: 'Flower Market in Merida, CN',
}));

export const v3InlineAccordian1 = {
  module: 'v3inlineAccordian',
  bgColor: 'PRIMARY',
  variation: '2-col image',
  headline: 'Popular places to visit',
  items: [...dualImageItem, ...dualImageItem, ...dualImageItem, ...dualImageItem],
};

export const v3InlineAccordian2 = {
  module: 'v3inlineAccordian',
  bgColor: 'SECONDARY',
  variation: '2-col image',
  headline: 'Popular places to visit',
  items: dualImageItem,
};

export const v3InlineAccordian3 = {
  module: 'v3inlineAccordian',
  bgColor: 'PRIMARY',
  variation: 'Full-width image',
  headline: 'Popular places to visit',
  items: fullWidthImageItem,
};

export const v3InlineAccordian4 = {
  module: 'v3inlineAccordian',
  bgColor: 'SECONDARY',
  variation: 'Full-width image',
  headline: 'Popular places to visit',
  items: fullWidthImageItem,
};

export const v3InlineAccordian5 = {
  module: 'v3inlineAccordian',
  bgColor: 'PRIMARY',
  variation: 'Text Only',
  headline: 'Popular places to visit',
  items,
};

export const v3InlineAccordian6 = {
  module: 'v3inlineAccordian',
  bgColor: 'SECONDARY',
  variation: 'Text Only',
  headline: 'Popular places to visit',
  items,
};

export const v3InlineAccordian7 = {
  module: 'v3inlineAccordian',
  bgColor: 'PRIMARY',
  variation: 'Horizontal Card',
  headline: 'Popular places to visit',
  items: [...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items],
};

export const v3InlineAccordian8 = {
  module: 'v3inlineAccordian',
  bgColor: 'SECONDARY',
  variation: 'Horizontal Card',
  headline: 'Popular places to visit',
  items,
};
