import { SanityApiSource } from 'src/sources';

class SanityApiSourceSingleton {
  private static instance: SanityApiSource;

  private constructor() {}

  public static setInstance(sanitySource: SanityApiSource) {
    if (!SanityApiSourceSingleton.instance) {
      SanityApiSourceSingleton.instance = sanitySource;
    }
  }

  public static getInstance(): SanityApiSource {
    return SanityApiSourceSingleton.instance;
  }
}

export default SanityApiSourceSingleton;