import { checkValue } from 'src/components/common/helper';
import { getDeviceImage } from 'src/components/common/theme/component';

//types
import {
  HeroImageProps,
  HeroImageCopyrightProps,
  HeroTextProps,
  HeroColorBarProps,
  HeroBackgroundVideoProps,
  HeroLogoProps,
  HeroOverlayImageProps,
  HeroGardientProps,
  HeroBreadcrumbProps,
  HeroScrollDownArrowProps,
  HeroForegroundVideoProps,
  HeroSearchWizardProps
} from './typings';

export const setGlobalHeroData = (heroData) => {
  const heroModules = [];
  if (checkValue(heroData)) {
    const {
      heroTitleDesktop,
      heroTitleMobile,
      heroSubTextDesktop,
      heroSubTextMobile,
      textColorNew,
      textSize,
      heroStyle,
      heroImage,
      backgroundPhotoMobile,
      heroImageDescription,
      heroImageCopyright,
      heroImageCaption,
      barColorLeft,
      barColorRight,
      backgroundVideoFile,
      backgroundVideoPath,
      backgroundPhotoStaticVideoImage,
      heroVideoYouTubeId,
      heroVideoHeight,
      heroVideoWidth,
      heroLogo,
      heroLogoMobile,
      heroLogoHeight,
      heroLogoWidth,
      heroLogoStyle,
      heroOverlayImageDesktop,
      heroOverlayImageMobile,
      heroSlimMode,
      opacitySelect,
      turnOffGradient,
      turnOffBreadcrumb,
      breadcrumbCurrentCopy,
      breadcrumbHomeCopy,
      breadcrumbParentCopy,
      breadcrumbParentUrl,
      isMiddleLayerEnabled,
      breadcrumbHomeUrl,
      heroScrollDownArrow,
      heroVideoPosition,
      backgroundColor,
      heroTitleWidth,
      heroSubTextWidth,
      turnOffCropsForHeroImage,
      heroSearchWizData,
      wizardLocalization,
      customeProp,
      hideSubheadlineOnMobile,
      turnOffRectangleBarsForMobile,
      heroAnchorLink,
      heroAnchorText,
      hideSoundControlsForVideo,
      foregroundVideoLeftRibbonColor,
      foregroundVideoMiddleRibbonColor,
      foregroundVideoRightRibbonColor,
      foregroundVideoPlayIconColor,
      foregroundVideoPlayIconSize,
      imagePosition
    } = heroData;

    let backgroundVideo;
    if (checkValue(backgroundVideoPath)) {
      backgroundVideo = [{url: backgroundVideoPath}];
    } else {
      backgroundVideo = backgroundVideoFile;
    }
    
    const isSearchWizShowInHero =  checkValue(heroSearchWizData?.isShowInHero)?heroSearchWizData?.isShowInHero:false;
    const isHerOverlayImage = checkValue(heroOverlayImageDesktop, true);
    const isVideo = checkValue(heroVideoYouTubeId) || checkValue(backgroundVideo?.[0]?.url);
    const isBackgroundVideo = isVideo && heroVideoPosition.toLowerCase() === 'background video';
    const isForegroundVideo = isVideo && heroVideoPosition.toLowerCase() === 'foreground video';

    //hero image module -> show only if !isBackgroundVideo && heroImage || backgroundColor is there
    if (!isBackgroundVideo && (checkValue(heroImage, true) || checkValue(backgroundColor))) {
      const heroImageModule: HeroImageProps = {
        heroImage,
        backgroundColor,
        backgroundPhotoMobile,
        heroImageDescription,
        turnOffCropsForHeroImage: checkValue(turnOffCropsForHeroImage)
          ? turnOffCropsForHeroImage.toLocaleLowerCase() === 'true'
          : false,
        heroSlimMode: checkValue(heroSlimMode)
          ? heroSlimMode.toLocaleLowerCase() === 'true'
          : false,
        template:customeProp?.template,
        imagePosition: imagePosition,
        module: 'heroImageModule',
      };
      heroModules.push(heroImageModule);
    }

    //hero background video module -> show only if isBackgroundVideo
    if (checkValue(isBackgroundVideo)) {
      const heroBackgroundVideoModule: HeroBackgroundVideoProps = {
        videoFileUrl: backgroundVideo,
        videoLink: heroVideoYouTubeId,
        videoPoster: backgroundPhotoStaticVideoImage,
        heroScrollDownArrow: heroScrollDownArrow,
        heroAnchorLink,
        heroAnchorText,
        hideSoundControlsForVideo,
        module: 'heroBackgroundVideoModule',
      };

      heroModules.push(heroBackgroundVideoModule);
    }

    //hero foreground video module -> show only if isForegroundVideo
    if (checkValue(isForegroundVideo)) {
      const heroForegroundVideoModule: HeroForegroundVideoProps = {
        videoFileUrl: backgroundVideo,
        videoLink: heroVideoYouTubeId,
        videoPoster: backgroundPhotoStaticVideoImage,
        width: heroVideoWidth,
        height: heroVideoHeight,
        backgroundBarColors: {
          foregroundVideoLeftRibbonColor,
          foregroundVideoMiddleRibbonColor,
          foregroundVideoRightRibbonColor
        },
        foregroundVideoPlayIconColor,
        foregroundVideoPlayIconSize,
        module: 'heroForegroundVideoModule',
      };

      heroModules.push(heroForegroundVideoModule);
    }

    //hero image caption module -> show only heroImageCaption || heroImageCopyright is there
    if (checkValue(heroImageCopyright) || checkValue(heroImageCaption)) {
      const heroImageCaptionModule: HeroImageCopyrightProps = {
        copyrightText: heroImageCopyright,
        copyrightCation: heroImageCaption,
        iconSize: 'sm',
        module: 'heroImageCaptionModule',
      };
      heroModules.push(heroImageCaptionModule);
    }

    //hero text module -> show only headline || subheadline is there and isHerOverlayImage ==false
    if (!isHerOverlayImage && (checkValue(heroTitleDesktop) || checkValue(heroSubTextDesktop))) {
      const heroTextModule: HeroTextProps = {
        heroTitleDesktop,
        heroTitleMobile,
        heroSubTextDesktop,
        heroSubTextMobile,
        textColor: textColorNew,
        textSize,
        heroStyle,
        heroTitleWidth,
        heroSubTextWidth,
        hideSubheadlineOnMobile,
        module: 'heroTextModule',
      };
      heroModules.push(heroTextModule);
    }

    //hero color bar module -> show only barColorRight || barColorLeft is there && isHerOverlayImage === false
    if (!isHerOverlayImage && (checkValue(barColorLeft) || checkValue(barColorRight))) {
      const heroColorBarModule: HeroColorBarProps = {
        barColorRight,
        barColorLeft,
        module: 'heroColorBarModule',
        turnOffRectangleBarsForMobile,
      };
      heroModules.push(heroColorBarModule);
    }

    //hero logo module -> show only heroLogo there && isHerOverlayImage === false
    if (!isHerOverlayImage && checkValue(heroLogo, true) && checkValue(getDeviceImage(heroLogo, 'desktop'))) {
      const heroLogoModule: HeroLogoProps = {
        heroLogo,
        heroLogoMobile,
        heroLogoHeight,
        heroLogoWidth,
        heroLogoStyle,
        module: 'heroLogoModule',
      };
      heroModules.push(heroLogoModule);
    }

    //hero overlay image -> show oonly isHerOverlayImage is true
    if (isHerOverlayImage) {
      const heroOverlayModule: HeroOverlayImageProps = {
        heroOverlayImageDesktop,
        heroOverlayImageMobile,
        module: 'heroOverlayModule',
      };
      heroModules.push(heroOverlayModule);
    }

    //hero gradient -> show only turnOffGradient === "False"
    if (turnOffGradient === 'False') {
      const heroGardientModule: HeroGardientProps = {
        turnOffGradient,
        opacitySelect,
        template:customeProp?.template,
        module: 'heroGardientModule',
      };
      heroModules.push(heroGardientModule);
    }

    //hero breadcrumb -> show only turnOffBreadcrumb === "False"
    if (turnOffBreadcrumb === 'False') {
      const heroBreadcrumbModule: HeroBreadcrumbProps = {
        turnOffBreadcrumb,
        breadcrumbCurrentCopy,
        breadcrumbHomeCopy,
        breadcrumbParentCopy,
        breadcrumbParentUrl,
        isMiddleLayerEnabled,
        breadcrumbHomeUrl,
        module: 'heroBreadcrumbModule',
      };
      heroModules.push(heroBreadcrumbModule);
    }

    //hero scrolldown arrow -> show only heroScrollDownArrow === "True"
    if (heroScrollDownArrow === 'True') {
      const heroScrollDownArrowModule: HeroScrollDownArrowProps = {
        heroScrollDownArrow,
        heroAnchorLink,
        heroAnchorText,
        module: 'heroScrollDownArrowModule',
      };
      heroModules.push(heroScrollDownArrowModule);
    }

    if(isSearchWizShowInHero === 'True'){
      const heroSearchWizardModule: HeroSearchWizardProps = {
        heroSearchWizData:heroSearchWizData,
        wizardLocalization:wizardLocalization,
        module: 'heroSearchWizardModule',
      };

      heroModules.push(heroSearchWizardModule);
    }
  }

  return heroModules;
};
