/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines */
import { action, observable } from 'mobx';
import { SerializedData } from 'bernie-core';
import { Store } from 'bernie-plugin-mobx';
import { Logger, NOOP_LOGGER } from 'bernie-logger';
import { ContextInput } from '@shared-ui/bex-api-context';
import { get } from 'lodash';

import {
  MemberWalletQuery,
  CampaignRecommendationsWithOffersQuery,
  GlobalHeaderQuery,
  CampaignByIdQuery,
  PropertySearchFiltersInput,
  PropertySort,
  PaginationInput,
  CustomerPropertySearchByIdQuery,
  PropertyFilterSearchQuery,
  PropertyDateRangeInput,
  PropertySearchCriteriaInput,
  InputMaybe,
  Scalars,
  OperationResult,
  LodgingPwaPropertySearch,
  FlightsShoppingPwaFlightSearchResultsFlightsSearch
} from 'src/common/__generated__/api';
import { BexApiSource } from '../sources/bex-api-source';
import { GeolocationObject, LoggedUserObject } from 'src/common/utils/types';

export class BexApiStore extends Store {
  @observable public name: string;
  @observable public memberWallet: OperationResult<MemberWalletQuery.Query>;
  @observable public campaignRecommendationsWithOffers: OperationResult<CampaignRecommendationsWithOffersQuery.Query>;
  @observable public campaignDealsById: OperationResult<CampaignByIdQuery.Query>;
  @observable public geoLocation: GeolocationObject | undefined;
  @observable public loggedUser: LoggedUserObject | undefined;
  @observable public propertyFilterSearch: OperationResult<PropertyFilterSearchQuery.Query>
  @observable public customerPropertySearchById: OperationResult<CustomerPropertySearchByIdQuery.Query>
  @observable public lodgingSearch: OperationResult<LodgingPwaPropertySearch.Query>
  @observable public flightSearch: OperationResult<FlightsShoppingPwaFlightSearchResultsFlightsSearch.Query>

  public constructor(private bexApiSource: BexApiSource, state: SerializedData = {}, logger: Logger = NOOP_LOGGER) {
    super(state, logger);
  }

  public hydrate(data: SerializedData): void {
    delete data.bexApiSource;
    Object.assign(this, data);
  }

  @action
  public async fetchGlobalHeaderQuery(context: ContextInput, pageId: string): Promise<any> {
    const variables: GlobalHeaderQuery.Variables = {
      context,
    };

    const data = await this.bexApiSource.globalHeaderQuery(variables, pageId);

    this.geoLocation = get(data, 'extensions.analytics[0].tealiumUtagData.Geo', {});
    return data;
  }

  @action
  public async fetchMemberWalletQuery(context: ContextInput, pageId: string): Promise<any> {
    const variables: MemberWalletQuery.Variables = {
      context,
    };

    const data = await this.bexApiSource.memberWalletQuery(variables, pageId);

    this.memberWallet = data;

    const username = get(
      get(data, 'data.memberWallet.info.items', []).filter(
        (x) => get(x, '__typename', '__typename') === 'MemberWalletHeading'
      ),
      '[0].text',
      'Null'
    );

    this.loggedUser = { name: get(username.split('Hi, '), '[1]', 'Null') };
    return data;
  }

  @action
  public async fetchCampaignRecommendationsWithOffersQuery(
    context: ContextInput,
    numCampaigns: number,
    pageNumberOfCampaigns: number,
    pageId: string,
    userLatitude?: number,
    userLongitude?: number
  ): Promise<any> {
    const variables: CampaignRecommendationsWithOffersQuery.Variables = {
      context,
      numCampaigns,
      pageNumberOfCampaigns,
    };

    const data = await this.bexApiSource.campaignRecommendationsWithOffersQuery(variables, pageId);

    this.campaignRecommendationsWithOffers = data;

    return data;
  }

  @action
  public async fetchCampaignByIdQuery(
    context: ContextInput,
    campaignId: string,
    pageNumber: number,
    pageId: string,
    userLatitude?: number,
    userLongitude?: number
  ): Promise<any> {
    try {
      const variables: CampaignByIdQuery.Variables = {
        context,
        campaignId: campaignId,
        pageNumber: pageNumber,
        enableTripAttach: false,
        displayPropertyCount: true,
        enableLivePricing: false,
        userLocation: {
          userLatitude: 1.29,
          userLongitude: 103.86
        },
      };

      const data = await this.bexApiSource.campaignByIdQuery(variables, pageId);

      this.campaignDealsById = data;

      return data;
    } catch {
      return { 'data': 'No Data' }
    }
  }

  @action
  public async fetchPropertyFilterSearchQuery(
    context: ContextInput,
    regionId: string,
    pageId: string,
    filters?: PropertySearchFiltersInput,
    sort?: PropertySort,
    searchPagination?: PaginationInput,
  ): Promise<any> {
    const variables: PropertyFilterSearchQuery.Variables = {
      context,
      regionId,
      filters,
      sort,
      searchPagination
    };

    try {
      const data = await this.bexApiSource.propertyFilterSearchQuery(variables, pageId);
      this.propertyFilterSearch = data;

      return data;
    } catch {
      return { 'data': 'No Data' }
    }
  }


  @action
  public async fetchCustomerPropertySearchByIdQuery(
    context: ContextInput,
    pageId: string,
    dateRange?: PropertyDateRangeInput,
    hotelIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>,
    criteria?: InputMaybe<PropertySearchCriteriaInput>
  ): Promise<any> {
    const variables: CustomerPropertySearchByIdQuery.Variables = {
      context,
      dateRange,
      hotelIds
    };

    try {
      if (this.customerPropertySearchById === undefined) {
        const data = await this.bexApiSource.customerPropertySearchByIdQuery(variables, pageId);
        this.customerPropertySearchById = data;
        return data;
      } else {
        return this.customerPropertySearchById;
      }

    } catch {
      return { 'data': 'No Data' }
    }
  }

  @action
  public async fetchPropertySearchQuery(variable: LodgingPwaPropertySearch.Variables, pageId: string): Promise<any> {
    const variables: LodgingPwaPropertySearch.Variables = { ...variable };
    try {
      const data = await this.bexApiSource.lodgingFilterSearchQuery(variables, pageId);
      return data;
    } catch {
      return { 'data': 'No Data' }
    }
  }

  @action
  public async fetchFlightSearchQuery(variable: FlightsShoppingPwaFlightSearchResultsFlightsSearch.Variables, pageId: string): Promise<any> {
    const variables: FlightsShoppingPwaFlightSearchResultsFlightsSearch.Variables = { ...variable };
    try {
      const data = await this.bexApiSource.flightFilterSearchQuery(variables, pageId);
      return data;
    } catch {
      return { 'data': 'No Data' }
    }
  }



  @action
  public getHotelsByStore() {
    return this.customerPropertySearchById;
  }

  @action
  public setName(name) {
    this.name = name;
  }
}
