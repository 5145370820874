/* Logs a custom event in Google Tag Manager. 
Set eventName parameter empty when the function is called, if you want to log only the parameters */
export const sendGTMDatalayerEvent = (eventName: string, parameters: { Tab_Text?: any; search_parameters?: any; search_parameters_2?: any; search_parameters_3?: any; search_url?: any; site_id?: any; campaign_url?: any; template?: any; partner_page?: any; Button_Text?: string; URL?: any; module_name?: string; direction?: string; category?: string; concierge_filter_parameters?: string; title?: any; }) => {
  const dataLayer = window['dataLayer'];
  let dataLaverEvent: any;
  if(eventName) {
    dataLaverEvent = {
      event: eventName,
      ...parameters
    };
  }
  else {
    dataLaverEvent = {
      ...parameters
    };
  }
  dataLayer.push(dataLaverEvent);
};

/* Splits long GTM parameter values to multiple strings 
as the maximum length of the parameter value GTM accepts is 100 */
export const splitGTMParameterValuesByMaxLength = (parameterText: string) => {
  return parameterText.match((/.{1,100}/g))
}

export const sendSearchWizardSearchGTMEvent = (wizardSearchEventName: string, wizardTab: string, searchParameters: string, searchUrl: string, additionalaParameters?: any) => {
  const searchParameterSplits = splitGTMParameterValuesByMaxLength(searchParameters);
  sendGTMDatalayerEvent(wizardSearchEventName, { 
    Tab_Text: wizardTab, 
    search_parameters: searchParameterSplits[0], 
    search_parameters_2: searchParameterSplits[1] ? searchParameterSplits[1] : '', 
    search_parameters_3: searchParameterSplits[2] ? searchParameterSplits[2] : '', 
    search_url: searchUrl,
    ...additionalaParameters
  });
}
