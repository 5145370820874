import fetch from 'isomorphic-fetch';
import { config } from 'src/config/config';

const MESO_CPCMSLITE_API = config?.caps_api;

// Language mapping for siteId
const siteLangMap = {
  '27': 'en_IN',
  '29': 'en_NZ' 
};

export class CpCmsLiteSource {
  private host: string;

  public constructor(host = 'gaia') {
    this.host = host;
  }

  public async fetchCpCMSLitePageData(
    templateName: string,
    feedName: string,
    pagelang: string,
    siteId: string,
    isCacheFeed: boolean
  ) {

    let isPreview = '';
    if (!isCacheFeed) {
      isPreview = '?ispreview=1';
    }

    // Special case where spotlight tnc page
    // /spotlight/tnc-xxx
    if(templateName === 'spotlight' ){
      feedName = feedName.toLowerCase().startsWith('tnc-') ? feedName.replace('tnc-',''): feedName;
    }
    if (siteLangMap[siteId]) {
      pagelang = siteLangMap[siteId];
    }
    
    const campFeedName = feedName !== '' ? feedName + '/' : '';
    const url = MESO_CPCMSLITE_API + templateName + '/' + campFeedName + pagelang + '/' + siteId + isPreview;
    //const url = 'http://localhost:3000/capsapi/template-cplite/heritage/en_US/1?ispriview=1';     
    return await fetch(url, {
      method: 'GET',
    })
      .then((res) =>
        res.json().then((response) => {
          return response;
        })
      )
      .catch((err) => ({
        error: err,
      }));
  }
}
