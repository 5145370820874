import { codeSplit, Controller } from 'bernie-core';
export class NotFoundController implements Controller {
  public pageId = 'page.notfound';
  public path = '/404';
  public routeName = '404';
  public bundles = [];
  public routeData = {
    pageName: 'page.notfound'
  };

  /**
   * Components may be referred to directly
   *   `public component = NotFoundPage;`
   *
   * Or, the preferred option is to create a code split point that will generate a new bundle.
   *   Use webpacks magic comments `webpackChunkName: "INSERT_FRIENDLY_NAME_HERE"`
   *   This will give it a friendly name for the name of the file.
   */
  public component = codeSplit(() => {
    return import(/* webpackChunkName: "not-found-page" */ '../../views/common/notfound-page')
  }
  );
  public exact = false;

  // tslint:disable-next-line:prefer-function-over-method
  public fetch() {
    return Promise.resolve({});
  }

  // tslint:disable-next-line:prefer-function-over-method
  public fetchPageData() {
    return Promise.resolve({ title: '404', pageName: 'page.notfound' });
  }
}
