import React, { useEffect, useState } from 'react';
import { isUndefined } from 'lodash';
import moment from 'moment';
interface IconProps {
  icon: {
    text?: string;
    iconPath: string;
    color?: string;
    width?: number;
    height?: number;
    iconExClass?: string;
  };
  background?: {
    bgShape: 'round' | 'square';
    className?: string;
    bgHeight?: number;
    bgWidth?: number;
    bgColor?: string;
  };
}

const Icon: React.FC<IconProps> = React.memo((props: IconProps) => {
  const {
    icon: { text = 'icon', iconPath, color, width = 24, height = 24, iconExClass = '' },
    background,
  } = props;

  const [iconUrl , setIconUrl] = useState(iconPath)

  useEffect(() => {
    const img = new Image();
    img.src = encodeURI(iconUrl);
    img.onload = function () {
      console.log('Image loaded successfully');
    };
    img.onerror = function () {
      console.log('Failed to load the image');
      const merge = iconUrl?.indexOf('?') > -1  ? "&" : "?"
      setIconUrl(`${iconUrl}${merge}d=${moment().utc().format('YYYYMMDDHH')}`)
    };
  } , [iconPath])

  let iconMask = {};
  iconMask = !isUndefined(color)
    ? {
        mask: `url(${iconUrl}) no-repeat center / contain`,
        WebkitMask: `url(${iconUrl}) no-repeat center / contain`,
      }
    : {
        background: `url(${iconUrl}) no-repeat center / contain`,
      };

  let bgStyles = {};
  let bgClassName = '';
  if (!isUndefined(background)) {
    const { className, bgShape, bgHeight = 40, bgWidth = 40, bgColor } = background;
    const bgShapeStyle = bgShape === 'round' ? { borderRadius: `${bgHeight / 2}px` } : {};
    bgClassName = className;
    bgStyles = {
      width: `${bgWidth}px`,
      height: `${bgHeight}px`,
      backgroundColor: `${bgColor}`,
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`,
      ...bgShapeStyle,
    };
  }

  return (
    <div style={bgStyles} className={bgClassName}>
      <div
        role="figure"
        aria-label={text}
        className={`svg-icon ${iconExClass}`}
        style={{
          width: `${width}px`,
          height: `${height}px`,
          backgroundColor: `${color}`,
          ...iconMask,
        }}
      ></div>
    </div>
  );
});

Icon.displayName = 'Icon';

export { Icon, IconProps };
