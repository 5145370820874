export const LightenDarkenColor = (hex, lum) => {
  if(!hex){
    return hex;
  } 
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }

  return rgb;
};

export const getQueryVariable = (queryString, variable) => {
  const query = queryString.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
};

export const checkValue = (value, isCheckLength = false) => {
  let returnVal = false;
  if (value && value !== undefined && value !== null && value !== '') {
    if (isCheckLength) {
      if (value.length > 0) {
        returnVal = true;
      }
    } else {
      returnVal = true;
    }
  }
  return returnVal;
};
