/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import React, { useRef } from 'react';
import { UitkRating } from 'uitk-react-rating';
import gaLogger from 'src/utils/ga-logger';
import { getParameterByName, getLink, checkValue, onImageLoad as imageLoader,appendMcicid, addExtraParamToUrl } from 'src/components/common/helper';
import Figure, { FigureProps } from 'src/components/common/ui/figure/figure';
import { Typo, Icon, IconProps } from 'src/components/common/ui';
import { PrimaryButton as PrimaryButtonEGDS, PrimaryButtonProps } from 'src/components/common/ui/button/button';
import { UitkButtonSize } from 'uitk-react-button';
import { CopyrightCameraProps } from 'src/components/common/modules/copyright-with-gradient/code-split/copyright-with-gradient-component';
import { Sticker } from 'src/components/common/ui';
import { UitkFigureAspectRatioType } from 'uitk-react-images';

const onClickGaTracking = (ga) => {
  const { eventCategory, eventAction, eventLabel } = ga;
  gaLogger.logGa(eventCategory, eventAction, eventLabel);
};

/**
 *
 * @param link
 * @param text
 * @param css
 * @param target
 * @returns
 */
const PrimaryButton = (
  link: string,
  text: string,
  css: string = '',
  target: string = '_blank',
  btntype: string = 'primary',
  ga: {
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
  }
) => {
  const url = addExtraParamToUrl(link);

  btntype = btntype === 'No CTA' ? 'nocta' : btntype;

  return text !== '' ? (
    <a
      href={url}
      data-url={url}
      className={`${css} ${btntype.toLowerCase()}`}
      target={target}
      onClick={() => onClickGaTracking(ga)}
    >
      {text}
    </a>
  ) : (
    <></>
  );
};

/**
 *
 * @param link
 * @param image
 * @param target
 * @param alt
 * @param css
 * @returns
 */
const ImageButton = (
  link: string,
  image: string,
  css: string = '',
  target: string = '_blank',
  alt: string = '',
  ga: {
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
  }
) => {
  const url = addExtraParamToUrl(link);
  return (
    <a href={url} className={`${css}`} target={target} onClick={() => onClickGaTracking(ga)}>
      <img src={image} alt={alt} className="lozad" />
    </a>
  );
};

/**
 *
 * @param imageSrc
 * @param blubText
 * @param url
 * @param target
 * @returns
 */

const ImageCard = (
  imageSrc: string,
  blubText: string,
  url: string,
  target: string = '',
  imgClass: string = '',
  ga: {
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
  },  
  imageAltText: string = '',
  roundcorner: any = false,
  extraInfo = null,
  verticleStripe = null,
  copyrightProps?: CopyrightCameraProps,
  ratio?: UitkFigureAspectRatioType,
  expTracking?: {
        templateType?: string;
        campaignUrl?: string;
        mainUrl?: string;
        referrerId?: string;
      }   
) => {

  let urlWithTracking = url;
  if (expTracking) {
    const { templateType, campaignUrl, mainUrl, referrerId = '' } = expTracking;
    urlWithTracking = appendMcicid(url, templateType, campaignUrl, mainUrl, referrerId);
  }

  const urlWithParam = addExtraParamToUrl(urlWithTracking);
  const tinyImage = tinyImageForCards(imageSrc, ga.eventAction);
 
  const figureProps: FigureProps = {
    src: tinyImage,
    exClass: 'lozad lozad g-image-gallery',
    imgClass: imgClass || '',
    alt: imageAltText,
    roundcorner: roundcorner,
    imageProps: {
      onLoad: (e) => {
        imageLoader(e.currentTarget, imageSrc);
      },
      'data-placeholder-background': '#e5e5e5',
      'data-text': blubText,
      'data-url': urlWithParam,
    },
    copyRightProps: copyrightProps,
    ratio
  };

  const ParentComponent = ({ children }) => {
    if (checkValue(url)) {
      return (
        <a href={urlWithParam} target={target} onClick={() => onClickGaTracking(ga)}>
          {children}
        </a>
      );
    } else {
      return <a>{children}</a>;
    }
  };

  return (
    <ParentComponent>
      <div className={`imgcard ${checkValue(url) ? 'imagecard-clickable' : ''}`}>
        {(checkValue(verticleStripe) && checkValue(verticleStripe?.position) && checkValue(verticleStripe?.color)) ? (
          <div
            className={`verticle-stripe ${verticleStripe.position}`}
            style={{ backgroundColor: `${verticleStripe.color}` }}
          />
        ) : null}
        <div className="img">
          <Figure {...figureProps} />
          {extraInfo ? (
            <div className="extra-info-inside-img">
              <Typo.HTMLText exClass="extra-info-inside-img-upper" tag="p">
                {extraInfo.info1}
              </Typo.HTMLText>
              <Typo.HTMLText exClass="extra-info-inside-img-lower" tag="p">
                {extraInfo.info2}
              </Typo.HTMLText>
              {extraInfo.info3 && <div className="extra-info-inside-img-link-text">{extraInfo.info3}</div>}
            </div>
          ) : null}
        </div>

        <Typo.HTMLText exClass="imgdesc imglbl" tag="p">
          {blubText}
        </Typo.HTMLText>
      </div>
    </ParentComponent>
  );
};
const ImageCardSingle = (
  imageSrc: string,
  blubText: string,
  url: string,
  target: string = '',
  imgClass: string = '',
  ga: {
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
  },
  imageAltText: string = '',
  roundcorner: any = false,
  copyrightProps?: CopyrightCameraProps
) => {
  const urlWithParam = addExtraParamToUrl(url);
  const tinyImage = tinyImageForSingleCard(imageSrc, ga.eventAction);
 
  const figureProps: FigureProps = {
    src: tinyImage,
    exClass: 'lozad',
    imgClass: imgClass || '',
    alt: imageAltText,
    roundcorner: roundcorner,
    imageProps: {
      onLoad: (e) => {
        imageLoader(e.currentTarget, imageSrc);
      },
      'data-placeholder-background': '#e5e5e5',
    },
    copyRightProps: copyrightProps,
  };

  const ParentComponent = ({ children }) => {
    if (checkValue(url)) {
      return (
        <a href={urlWithParam} target={target} onClick={() => onClickGaTracking(ga)}>
          {children}
        </a>
      );
    } else {
      return <a>{children}</a>;
    }
  };

  return (
    <ParentComponent>
      <div className={`imgcard ${checkValue(url) ? 'imagecard-clickable' : ''}`}>
        <div className="img">
          <Figure {...figureProps} />
        </div>
        <Typo.HTMLText exClass="imgdesc imglbl" tag="p">
          {blubText}
        </Typo.HTMLText>
      </div>
    </ParentComponent>
  );
};

/**
 *
 * @param link
 * @param socialTypeClass
 * @param socialText
 * @returns
 */
const SocialLink = (
  link: string,
  socialTypeClass: string,
  socialText: string,
  ga: {
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
  }
) => {
  return (
    <a href={link} className={socialTypeClass} target="_blank" onClick={() => onClickGaTracking(ga)}>
      <div className={`s-icon ${socialTypeClass}`}></div>
      <span>{socialText}</span>
    </a>
  );
};

/**
 *
 * @param link
 * @param text
 * @param isPipActive
 * @returns
 */
const FooterLink = (
  link: string,
  text: string,
  isPipActive: boolean = false,
  ga: {
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
  }
) => {
  return (
    <>
      <a href={link} data-url={link} target="_blank" onClick={() => onClickGaTracking(ga)}>
        {text}
      </a>
      {isPipActive && <span> |</span>}
    </>
  );
};

/**
 *
 * @param imageSrc
 * @param hName
 * @param rating
 * @param details
 * @param price
 * @param btnUrl
 * @param localizationText
 * @returns
 */
const HotelCard = (
  imageSrc: string,
  hName: string,
  rating: string,
  details: string,
  price: string,
  btnUrl: string,
  hotelCardCtaStyle: string,
  ga: {
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
  },
  localizationText?: {
    btnText: string;
    fromText: string;
    pricePerText: string;
  },
  imageAltText: string = ''
) => {
  const htlUrl =
    btnUrl.indexOf('mcicid') > -1
      ? btnUrl
      : btnUrl.indexOf('?') > -1
      ? btnUrl + '&mcicid=cmslite'
      : btnUrl + '?mcicid=cmslite';
  return (
    <div className="hotel-card">
      <div className="hotel-card-wrapper">
        <div className="img-content">{ImageCard(`${imageSrc}`, '', htlUrl, '_blank', '', ga, imageAltText)}</div>
        <div className="details-content">
          <h4>{hName}</h4>
          <div className="rating">
            <UitkRating rating={rating} />
          </div>
          <div className="details bodytxtsm">{details}</div>
          <div className="hotel-action">
            <div className="price-wrapper">
              <div className="from bodytxtsm">{localizationText?.fromText}</div>
              <div className="price">
                <span className="bold linktxt">{price}&nbsp;</span>
                <span className="label bodytxtsm">{localizationText?.pricePerText}</span>
              </div>
            </div>
            <div className="cta">
              {PrimaryButton(
                htlUrl,
                localizationText?.btnText,
                'btn htl-card-cta-btn btn-global',
                '_blank',
                hotelCardCtaStyle,
                ga
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 *
 * @param
 */

const ContentPanelCard = (
  imageSrc: string,
  heading: string,
  description: string,
  btnLink: string,
  btnText: string,
  target: string = '',
  btnType = 'primary',
  ga: {
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
  },
  cpImageAltText: string,
  getClientHeight?: any,
  variationStyle?: string,
  backgroundColor?: string,
  topRightRibbonColor?: string,
  topLeftRibbonColor?: string,
  bottomRightRibbonColor?: string,
  bottomLeftRibbonColor?: string,
  stickerProps?: any,
  stickerInsideSlideCases?: boolean,
  contentTextStyle?: string
) => {
  const isRibbonsAvailable =
    checkValue(topLeftRibbonColor) ||
    checkValue(topRightRibbonColor) ||
    checkValue(bottomLeftRibbonColor) ||
    checkValue(bottomRightRibbonColor);

  const imgref = useRef(null);
  const tinyImage = imageSrc.replace('width=685&height=495', 'width=7&height=5');
  const onImageLoad = (e) => {
    imageLoader(e.currentTarget, imageSrc)
    getClientHeight(imgref.current.clientHeight);
  };

  const primaryButtonProps: PrimaryButtonProps = {
    text: btnText,
    tag: 'a',
    gaTracking: ga,
    exClass: `btn g-content-panel-cta`,
    url: btnLink,
    target: target,
    size: UitkButtonSize.LARGE,
  };

  const colorRibbons = () => (
    <>
      {topLeftRibbonColor ? (
        <div className="color-bar top-left" style={{ backgroundColor: topLeftRibbonColor }} />
      ) : null}
      {topRightRibbonColor ? (
        <div className="color-bar top-right" style={{ backgroundColor: topRightRibbonColor }} />
      ) : null}
      {bottomLeftRibbonColor ? (
        <div className="color-bar bottom-left" style={{ backgroundColor: bottomLeftRibbonColor }} />
      ) : null}
      {bottomRightRibbonColor ? (
        <div className="color-bar bottom-right" style={{ backgroundColor: bottomRightRibbonColor }} />
      ) : null}
    </>
  );

  const img = () => (
    <div className={`content-panel-main-img img ${isRibbonsAvailable ? 'image-with-ribbons' : ''}`}>
      {isRibbonsAvailable && colorRibbons()}
      {stickerInsideSlideCases ? <Sticker {...stickerProps} /> : null}
      <img
        src={tinyImage}
        data-placeholder-background="#e5e5e5"
        alt={cpImageAltText}
        className="lozad"
        onLoad={(e) => {
          onImageLoad(e);
        }}
        ref={imgref}
      />
    </div>
  );

  const isTopHeading = variationStyle === 'v1';

  const contentPanelV1 = () => (
    <div
      className={`
      content-panel-card
      ${stickerProps?.stickerImage ? 'with-sticker' : ''}
      ${stickerProps?.stickerPosition === 'bottom-right' ? 'sticker-bottom-right' : 'sticker-bottom-left'}
      ${checkValue(backgroundColor) ? 'inner-background' : ''}

    `}
    >
      {checkValue(heading) && contentTextStyle === "Item-Headline-top-left" ? ( 
        <Typo.HTMLText exClass= "top-heading-desktop override-heading-font-family item-Headline-top-left-heading" tag="h2">
          {heading}
        </Typo.HTMLText>
      ) : null}
      <div className="card-wrapper">
        <div
          className={`order-style ${isTopHeading ? 'top-heading' : ''}`}
          style={{ backgroundColor: `${checkValue(backgroundColor) ? backgroundColor : ''}` }}
        >
           

          {isTopHeading ? (
            <>
              {checkValue(heading) ? (
                <Typo.HTMLText exClass="top-heading-mobile override-heading-font-family" tag="h2">
                  {heading}
                </Typo.HTMLText>
              ) : null}
              <Typo.HTMLText exClass="bodytxtmd top-heading-mobile" tag="p">
                {description}
              </Typo.HTMLText>
            </>
          ) : null}
          <div className={`description ${isTopHeading ? 'top-heading-desktop' : ''}`}>
            {checkValue(heading) && contentTextStyle !== "Item-Headline-top-left" ? (
              <Typo.HTMLText
                tag="h2"
                exClass={`${isTopHeading ? 'top-heading-desktop' : ''} override-heading-font-family`}
              >
                {heading}
              </Typo.HTMLText>
            ) : null}
            <Typo.HTMLText exClass={`bodytxtmd ${isTopHeading ? 'top-heading-desktop' : ''}`} tag="p">
              {description}
            </Typo.HTMLText>
            {checkValue(btnText) && checkValue(btnLink) && (
              <div className="cta-action" data-url={btnLink}>
                <PrimaryButtonEGDS {...primaryButtonProps} />
              </div>
            )}
          </div>
          {img()}
        </div>
        {checkValue(btnText) && checkValue(btnLink) && (
          <div className={`mobile ${isTopHeading ? 'top-heading-mobile-cta' : ''}`}>
            <div className="cta-action" data-url={btnLink}>
              <PrimaryButtonEGDS {...primaryButtonProps} />
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const contentPanelV2 = () => (
    <div
      className={`
      content-panel-card
      ${stickerProps?.stickerImage ? 'with-sticker' : ''}
      ${stickerProps?.stickerPosition === 'bottom-right' ? 'sticker-bottom-right' : 'sticker-bottom-left'}
      content-panel-v2
      ${checkValue(backgroundColor) ? 'inner-background' : ''}`}
    >
      <div className="card-wrapper">
        <div
          className="order-style"
          style={{ backgroundColor: `${checkValue(backgroundColor) ? backgroundColor : ''}` }}
        >
          <div className="description">
            <Typo.HTMLText exClass="override-heading-font-family" tag="h2">
              {heading}
            </Typo.HTMLText>
            <Typo.HTMLText exClass="bodytxtmd" tag="p">
              {description}
            </Typo.HTMLText>
            {checkValue(btnText) && checkValue(btnLink) && (
              <div className="cta-action">
                <PrimaryButtonEGDS {...primaryButtonProps} />
              </div>
            )}
          </div>
          {img()}
        </div>
        {checkValue(btnText) && checkValue(btnLink) && (
          <div className="mobile">
            <div className="cta-action">
              <PrimaryButtonEGDS {...primaryButtonProps} />
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return variationStyle === 'v2' ? contentPanelV2() : contentPanelV1();
};

/**
 *
 * @param imageSrc
 * @param text
 * @param css
 * @returns
 */
const IconCard = (imageSrc: string, text: string, css: string = '') => {
  return (
    <div className={`icon-card ${css}`}>
      <img src={imageSrc} alt={text} />
      <p className="bodytxtreg">{text}</p>
    </div>
  );
};

/**
 * @param imageArray
 * @param device
 */
const getDeviceImage = (imageArray: any, device: string, exactPath = false) => {
  let image = '';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  if (exactPath) {
    imageArray !== '' &&
      imageArray?.map((imgItem) => {
        if (imgItem.name === device) {
          image = imgItem.url;
        }
      });
  } else {
    imageArray !== '' &&
      imageArray?.map((imgItem) => {
        if (imgItem.name.includes(device)) {
          image = imgItem.url;
        }
      });
  }
  return image;
};

const useProgressiveImg = (lowQualitySrc, highQualitySrc) => {
  const [src, setSrc] = React.useState(lowQualitySrc);

  React.useEffect(() => {
    setSrc(lowQualitySrc);

    const img = new Image();
    img.src = encodeURI(highQualitySrc);
    
    img.onload = () => {
      setSrc(encodeURI(highQualitySrc));
    };
  }, [lowQualitySrc, highQualitySrc]);

  return [src, { blur: src === lowQualitySrc }];
};

const tinyImage = (image, device) => {
  return device === 'desktop'
    ? image.replace('width=2016&height=1092', 'width=37&height=17')
    : device === 'tablet'
    ? image.replace('width=768&height=500', 'width=26&height=17')
    : device === 'mobile'
    ? image.replace('width=375&height=500', 'width=15&height=20')
    : image;
};

const tinyImageForCards = (image, module) => {
  return module.indexOf('Hotel') > -1
    ? image.replace('_y.', '_e.')
    : image.replace('width=500&height=720', 'width=5&height=8');
};
const tinyImageForSingleCard = (image, module) => {
  return module.indexOf('Hotel') > -1
    ? image.replace('_y.', '_e.')
    : image.replace('width=640&height=390', 'width=7&height=5');
};

const tinyImageForProductCard = (image) => {
  return image.replace('width=640&height=390', 'width=7&height=5');
};

export {
  PrimaryButton,
  ImageButton,
  ImageCard,
  ImageCardSingle,
  SocialLink,
  FooterLink,
  HotelCard,
  ContentPanelCard,
  IconCard,
  getDeviceImage,
  useProgressiveImg,
  tinyImage,
  tinyImageForProductCard,
  addExtraParamToUrl,
  tinyImageForCards,
};
