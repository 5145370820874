const v3Seo = {
  partnerName: '',
  partnerLogo: '',
  partnerLogoMulti: [
    {
      name: 'desktop',
      url: 'https://mesocp3.azureedge.net/media/mj1oyd0r/vgps_fyo_logo_h_180x70-1.jpg?v=6-24-20245:58:33AM&quality=85',
    },
  ],
  partnerLogoNew: '',
  partnerCtaText: 'START PLANNING',
  partnerCtaUrl: '',
  partnerMessage: '',
  partnerUrl: '',
  socialModule: [
    {
      link: 'https://www.facebook.com/VisitGreaterPS/',
      uniquekey: 'socialModule-0',
      socialType: 'facebook',
      module: 'socialModule',
    },
    {
      link: 'https://www.instagram.com/visitgreaterps/',
      uniquekey: 'socialModule-1',
      socialType: 'instagram',
      module: 'socialModule',
    },
    {
      link: 'https://www.pinterest.com/visitgreaterps/',
      uniquekey: 'socialModule-2',
      socialType: 'pinterest',
      module: 'socialModule',
    },
    {
      link: 'https://www.youtube.com/user/PalmSpringsUSA',
      uniquekey: 'socialModule-3',
      socialType: 'youtube',
      module: 'socialModule',
    },
  ],
  partnerHeaderBackgroundColor: '#ffffff',
  partnerHeaderTextColor: 'Dark',
  partnerMessageUrl: '',
  partnerHeaderSize: '',
  partnerCtaColor: '',
  partnerHeaderPlaceholderText: '',
  partnerHeaderPlaceholderUrl: '',
  openPartnerUrlInSameTab: 'False',
  partnerCtaTextColor: '',
  partnerCtaHoverColor: '',
  partnerCtaHoverTextColor: '',
  partnerHeaderNavigation: [
    {
      navigationItemText: 'Home',
      uniquekey: 'partnerHeaderNavigation-0',
      navigationItemUrl: '/see/palm-springs-2024-v3-1',
      openUrlInNewTab: 'False',
      navigationItemOrderFixed: 'False',
      module: 'navigationItemReuse',
    },
    {
      navigationItemText: 'Romantic retreat',
      uniquekey: 'partnerHeaderNavigation-1',
      navigationItemUrl: '/see/palm-springs-2024-v3-1/akil-and-marco-v3-1',
      openUrlInNewTab: 'False',
      navigationItemOrderFixed: 'False',
      module: 'navigationItemReuse',
    },
  ],
  primaryColour: '#1668e3',
  secondaryColour: '#35c1cf',
  subHeaderHamburgerMenuIconColor: '#141d38',
  subHeaderHamburgerMenuBackgroundColor: '',
  subHeaderHamburgerMenuTextColor: '',
  subHeaderHamburgerMenuBackgroundHoverColor: '#1668e3',
  subHeaderHamburgerMenuTextHoverColor: '#ffffff',
  subHeaderHamburgerMenuBackgroundSelectedColor: '#e6e6e6',
  subHeaderMenuPosition: 'Center',
  lozengeMessage: '',
  lozengeDetailMessage:
    'This content was paid for by Visit Greater Palm Springs and created by Expedia Group Media Studio. The editorial staff of Expedia Group companies had no role in this content.',
  lozengeTextColor: '',
  lozengeBackgroundColor: '',
  lozengeIconColor: '',
  turnOffHeader: 'False',
  turnOffSubheader: 'False',
  turnOffSubHeaderHamburgerMenu: 'True',
  pageTheme: 'Brand-Light',
  breadcrumbCurrentCopy: 'Level 1',
  breadcrumbHomeCopy: 'Home',
  breadcrumbParentCopy: 'Level 2',
  breadcrumbParentUrl: 'https://www.expedia.com',
  isMiddleLayerEnabled: 'True',
  breadcrumbHomeUrl: '',
};

export default v3Seo;
