import { AdditionalMeta, PropertyMeta, NamedMeta } from 'bernie-http';

export function buildNameCorrectionPageData() {
  // const additionalMetas: AdditionalMeta[] = [...buildPropertyMeta()];

  return {
    title: 'Request a name correction to a Flight or Package booking',
    pageName: 'page.name-correction',
    seo: {
      //description: 'Expedia.com.au - Request a name correction to a Flight or Package booking',
      canonical: `https://www.expedia.com/travelsupport/name-correction`
    }
    // seo: {
    //   description: 'Go to Japan',
    //   canonical: 'https://www.expedia.co.jp/gototravelrefund',
    //   additionalMeta: additionalMetas
    // }
  };
}

function buildPropertyMeta() {
  const ogtitle = new PropertyMeta('og:title', 'Go to travel refund');
  const ogdescription = new PropertyMeta('og:description', 'Go to travel refund');
  const ogimage = new PropertyMeta(
    'og:image',
    'https://a.travel-assets.com/travel-assets-manager/vbau-imagery/bg-img-1-min-200.jpg'
  );
  const ogurl = new PropertyMeta('og:url', 'https://www.expedia.com.au/findyourgreatbritain');
  const ogtype = new PropertyMeta('og:type', 'website');

  const viewportMeta = new NamedMeta(
    'viewport',
    'width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, target-densityDpi=device-dpi'
  );

  return [viewportMeta, ogurl, ogtype, ogtitle, ogimage, ogdescription];
}
