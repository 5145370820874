import * as React from 'react';

import { FigureMap } from './figure-map';
import { FigureProps } from 'src/components/common/ui/figure/figure';

/**
 * Looks at the code-split figure map and renders the desired ui component based on the name.
 */
export const FigureComponent = (props: FigureProps) => {
  const component = FigureMap;

  if (!component) {
    return null;
  }

  return React.createElement(component as any, props);
};
