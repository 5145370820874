export const loadBrandData = async brand => {
    
    const brandHash = (brand: string) => {
    if (!brand) {
      return '';
    }
  
    const code = Array.from(brand)
      .map((c) => c.charCodeAt(0))
      .join('');

    return "brand-" + code;
  };

    const brandMap = {
      "brand-1171001159510010110297117108116": async () => await import(/* webpackChunkName: "brand-1171001159510010110297117108116" */ "./uds_default"),"brand-10112011210110010597": async () => await import(/* webpackChunkName: "brand-10112011210110010597" */ "./expedia"),"brand-104111116101108115": async () => await import(/* webpackChunkName: "brand-104111116101108115" */ "./hotels"),"brand-10198111111107101114115": async () => await import(/* webpackChunkName: "brand-10198111111107101114115" */ "./ebookers"),"brand-11111498105116122": async () => await import(/* webpackChunkName: "brand-11111498105116122" */ "./orbitz"),"brand-991041019711211610599107101116115": async () => await import(/* webpackChunkName: "brand-991041019711211610599107101116115" */ "./cheaptickets"),"brand-10897115116109105110117116101": async () => await import(/* webpackChunkName: "brand-10897115116109105110117116101" */ "./lastminute"),"brand-109114106101116": async () => await import(/* webpackChunkName: "brand-109114106101116" */ "./mrjet"),"brand-1161149711810110811199105116121": async () => await import(/* webpackChunkName: "brand-1161149711810110811199105116121" */ "./travelocity"),"brand-119111116105102": async () => await import(/* webpackChunkName: "brand-119111116105102" */ "./wotif"),"brand-11811498111": async () => await import(/* webpackChunkName: "brand-11811498111" */ "./vrbo")
    };
    const brandIndex = brandHash(brand);

    if (brandIndex in brandMap) {
      return await brandMap[brandIndex]();
    } else {
      return await brandMap['brand-1171001159510010110297117108116']();
    }
  };
  