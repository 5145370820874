import { createContext, useContext } from "react";

const ComponentContext = createContext<{ componentProps: any } | null>(null);

export function useComponentContext() {
  const context = useContext(ComponentContext);
  if (!context) {
    throw new Error('Must be render as child of component');
  }
  return context;
}

export default ComponentContext;