import reactGA from 'react-ga';
import { sendGTMDatalayerEvent } from 'src/components/common/utils/gtmHelperFunctions';
import { config } from 'src/config/config';

const GA_ACCOUNT = config?.ga_account;

const partnerPageName = (partnerName) => {
  const mainTemplates = ['mirror', 'spotlight'];
  return mainTemplates.indexOf(partnerName?.toLowerCase()) < 0 ? partnerName : '';
};

export default {
  logTraffic: (siteId, campaignUrl, template, partnerName) => {
    /*console.log('------')
    console.log(`SiteID:${siteId} | CampaignUrl:${campaignUrl} | Template:${template}`)
    console.log('------')*/

    reactGA.set({
      dimension1: siteId,
      dimension3: campaignUrl,
      dimension4: template,
      dimension5: partnerPageName(partnerName),
    });
    reactGA.pageview(window.location.hostname + window.location.pathname + window.location.search);

    sendGTMDatalayerEvent('', { 
      site_id: siteId, 
      campaign_url: campaignUrl, 
      template: template, 
      partner_page: partnerPageName(partnerName) 
    });
  },

  initializeGa: () => {
    try {
      reactGA.initialize(GA_ACCOUNT);
    } catch (err) {
      console.error('Error initializeGa');
    }
  },

  logGaWithMetrics: (eventCategory, eventAction, eventLabel) => {
    /*console.log('------')
    console.log(`EventCategory:${eventCategory} | EventAction:${eventAction} | EventLabel:${eventLabel} `)
    console.log('------')*/

    const objMetrics = {
      metric1: 0,
      metric2: 0,
      metric3: 0,
      metric4: 0,
      metric5: 0,
    };

    switch (eventLabel) {
      case '20':
        objMetrics.metric1 = 1;
        break;
      case '40':
        objMetrics.metric2 = 1;
        break;
      case '60':
        objMetrics.metric3 = 1;
        break;
      case '80':
        objMetrics.metric4 = 1;
        break;
      case '100':
        objMetrics.metric5 = 1;
        break;
    }

    reactGA.set(objMetrics);
    reactGA.event({ category: eventCategory, action: eventAction, label: eventLabel });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  logGa: (eventCategory, eventAction, eventLabel, value = 0) => {
    /*console.log('------')*/
    /*console.log('------')*/
    //console.log(`EventCategory:${eventCategory} | EventAction:${eventAction} | EventLabel:${eventLabel} `)
    // Commented for testing need to reactive
    if (value !== 0) {
      //reactGA.event({ category: eventCategory, action: eventAction, label: eventLabel, value:value });
      reactGA.ga('send', {
        hitType: 'event',
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: eventLabel,
        dimension6: value,
      });
    } else {
      reactGA.event({ category: eventCategory, action: eventAction, label: eventLabel });
    }
  },

  logGaUserStay: () => {
    // Usage of this function is disabled since it has caused wrong data in GA showing more sessions than there is.
    let mints = 0;
    let secs = 0;
    const gaTrackingUserStay = () => {
      reactGA.event({
        category: 'Dwell',
        action: `${mints} min ${secs == 0 ? '00' : secs} sec dwell`,
        label: window?.location?.href,
      });
      secs = Number(secs + 20);
      if (secs === 60) {
        secs = 0;
        mints += 1;
      }

      if (mints == 10 && secs == 20) {
        clearInterval(tid);
      }
    };

    const tid = setInterval(gaTrackingUserStay, 20000);
  },
  logCustomDimensions: (dimension, event, value) => {
    reactGA.ga('send', event, {
      [dimension]: value,
    });
  },
};
