/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines */
import { toJS } from 'mobx';

import { merge, SerializedData } from 'bernie-core';

import { getErrorString, Logger, NOOP_LOGGER, SystemEvent } from 'bernie-logger';
import { SOURCE_EVENTS } from './events/source-events';

import { Sdk, OperationResult } from 'src/common/__generated__/api';
import { MemberWalletQuery, CampaignRecommendationsWithOffersQuery, GlobalHeaderQuery, CampaignByIdQuery, PropertyFilterSearchQuery, CustomerPropertySearchByIdQuery, LodgingPwaPropertySearch, FlightsShoppingPwaFlightSearchResultsFlightsSearch } from 'src/common/__generated__/api/types';

const { withoutKeys } = merge;
export class BexApiSource {
  private readonly api: Sdk;
  private readonly logger: Logger;

  constructor(api: Sdk, logger: Logger = NOOP_LOGGER) {    
    this.api = api;
    this.logger = logger;
  }

  public globalHeaderQuery(
    variables: GlobalHeaderQuery.Variables,
    pageId: string
  ): Promise<OperationResult<GlobalHeaderQuery.Query>> {
    const requestOptions = { 'x-page-id': pageId };    

    return this.api
      .GlobalHeaderQuery(variables, requestOptions)
      .catch(this.getErrorHandler(variables, SOURCE_EVENTS.MESO3_BEX_API_MEMBER_WALLET_FETCH_ERROR));
  }

  public memberWalletQuery(
    variables: MemberWalletQuery.Variables,
    pageId: string
  ): Promise<OperationResult<MemberWalletQuery.Query>> {
    const requestOptions = { 'x-page-id': pageId };

    return this.api
      .MemberWalletQuery(variables, requestOptions)
      .catch(this.getErrorHandler(variables, SOURCE_EVENTS.MESO3_BEX_API_MEMBER_WALLET_FETCH_ERROR));
  }

  public campaignRecommendationsWithOffersQuery(
    variables: CampaignRecommendationsWithOffersQuery.Variables,
    pageId: string
  ): Promise<OperationResult<CampaignRecommendationsWithOffersQuery.Query>> {
    const requestOptions = { 'x-page-id': pageId, 'x-enable-apq': 'true' };

    return this.api
      .CampaignRecommendationsWithOffersQuery(variables, requestOptions)
      .catch(this.getErrorHandler(variables, SOURCE_EVENTS.MESO3_BEX_API_CAMPAIGN_RECOMMENDATION_WITH_OFFERS_FETCH_ERROR));
  }

  public campaignByIdQuery(
    variables: CampaignByIdQuery.Variables,
    pageId: string
  ): Promise<OperationResult<CampaignByIdQuery.Query>> {
    const requestOptions = { 'x-page-id': pageId, 'x-enable-apq': 'true' };

    return this.api
      .CampaignByIdQuery(variables, requestOptions)
      .catch(this.getErrorHandler(variables, SOURCE_EVENTS.MESO3_BEX_API_CAMPAIGN_BY_ID_FETCH_ERROR));
  }

  public propertyFilterSearchQuery(
    variables: PropertyFilterSearchQuery.Variables,
    pageId: string
  ): Promise<OperationResult<PropertyFilterSearchQuery.Query>> {
    const requestOptions = { 'x-page-id': pageId, 'x-enable-apq': 'true' };

    return this.api
      .PropertyFilterSearchQuery(variables, requestOptions)
      .catch(this.getErrorHandler(variables, SOURCE_EVENTS.MESO3_BEX_API_CAMPAIGN_BY_ID_FETCH_ERROR));
  }
  
  public customerPropertySearchByIdQuery(
    variables: CustomerPropertySearchByIdQuery.Variables,
    pageId: string
  ): Promise<OperationResult<CustomerPropertySearchByIdQuery.Query>> {
    //const requestOptions = { 'x-page-id': pageId};   

    return this.api
      .CustomerPropertySearchByIdQuery(variables)
      .catch(this.getErrorHandler(variables, SOURCE_EVENTS.MESO3_BEX_API_CUSTOMER_PROPERTY_SEARCH_ERROR));
  }

  public lodgingFilterSearchQuery(
    variables: LodgingPwaPropertySearch.Variables,
    pageId: string
  ): Promise<OperationResult<LodgingPwaPropertySearch.Query>> {
    const requestOptions = { 'x-page-id': pageId, 'x-enable-apq': 'true' };

    return this.api
      .LodgingPwaPropertySearch({...variables}, requestOptions)
      .catch(this.getErrorHandler(variables, SOURCE_EVENTS.MESO3_BEX_API_LODGING_SEARCH_ERROR));
  }

  public flightFilterSearchQuery(
    variables: FlightsShoppingPwaFlightSearchResultsFlightsSearch.Variables,
    pageId: string
  ): Promise<OperationResult<FlightsShoppingPwaFlightSearchResultsFlightsSearch.Query>> {
    const requestOptions = { 'x-page-id': pageId, 'x-enable-apq': 'true' };

    return this.api
      .FlightsShoppingPwaFlightSearchResultsFlightsSearch({...variables}, requestOptions)
      .catch(this.getErrorHandler(variables, SOURCE_EVENTS.MESO3_BEX_API_FLIGHT_SEARCH_ERROR));
  }

  private getErrorHandler(variables: any, sourceEvent: SystemEvent) {
    return (error: any) => {
      this.logger.logEvent(sourceEvent, variables, getErrorString(error));
      // Throw the error to the next catch block since it is not resolved here
      throw error;
    };
  }

   /**
   * Override the default `toJson` to exclude the Apollo client.
   * If we don't include this, we will get a JSON serialization error.
   *
   * Creates a basic JSON object from the store, excluding
   * any fields provided in `ignoreAdditionalKeys`.
   */
   public toJSON(ignoreAdditionalKeys: string[] | string = []): SerializedData {
    if (!Array.isArray(ignoreAdditionalKeys)) {
      ignoreAdditionalKeys = ignoreAdditionalKeys.constructor === String ? [ignoreAdditionalKeys] : [];
    }

    const regexIgnorePrivate = /^_.+/;
    const simpleObject: any = toJS(this);
    const ignoreKeys: string[] = Object.keys(simpleObject)
      .filter(key => regexIgnorePrivate.test(key) || typeof simpleObject[key] === 'function')
      .concat(ignoreAdditionalKeys, 'logger', 'api', 'headers', 'apiWithFederation');

    return withoutKeys(ignoreKeys).merge({}, simpleObject);
  }
}
