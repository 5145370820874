import { ClientFactories, ContextStore, Store } from 'bernie-plugin-mobx';
import { NOOP_LOGGER } from 'bernie-logger';
import { ClientLogger } from 'bernie-client';
import { ContextInput } from 'bernie-context';

import { BexApiSource, CpCmsLiteSource, SanityApiSource } from '../sources';
import { getBexApiSdk } from '../sources/get-bex-api-sdk';
import { BexApiStore } from './bex-api-store';
import { CpCmsLiteStore } from './cp-cmslite-store';

// Sanity services
import { getSanityApiSdk } from '../sources/services/sanity.service';
import { SanityStore } from './cp-sanity-store';

export { BexApiStore, CpCmsLiteStore, SanityStore };

export const buildClientSideBexApiSource = (contextInput: ContextInput): BexApiSource => {
    const headers = { 'Device-User-Agent-ID': contextInput.identity.duaid };
    const api = getBexApiSdk(headers);

    return new BexApiSource(api, ClientLogger.getLoggerWithIdentifier('pwa.source.bexapi'));
};

export const buildSanityApiSource = (): SanityApiSource => {
    const api = getSanityApiSdk();   
    return new SanityApiSource(api, ClientLogger.getLoggerWithIdentifier('sanity.source'));
}

export const stores: ClientFactories = {
    // Ignoring next line cause ContextStore doesn't provide static DEFAULT values
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    context: (): Store => new ContextStore(null, NOOP_LOGGER),

    bexapistore: ({ bexApiContext }): Store =>
        new BexApiStore(
            buildClientSideBexApiSource(bexApiContext),
            {},
            NOOP_LOGGER
        ),
    cpcmslitefeed: (): Store =>
        new CpCmsLiteStore(
            new CpCmsLiteSource(),
            {},
            NOOP_LOGGER
        ),
    sanityStore: (): Store =>
        new SanityStore(
            buildSanityApiSource(),
            {},
            NOOP_LOGGER
        ),
};