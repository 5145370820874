function isKeyPresentInSessionStorage(key: string): boolean {
  if (typeof sessionStorage !== 'undefined') {
    return sessionStorage.getItem(key) !== null;
  }

  return false;
}

export const DISABLE_APQ_KEY = 'disableAPQ';
export function isAPQDisabledOnWeb(): boolean {
  return isKeyPresentInSessionStorage(DISABLE_APQ_KEY);
}