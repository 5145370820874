
const xorEncrypt = (key: string, secret: string): string => {
  let encrypted = '';
  for (let i = 0; i < key.length; i++) {
      encrypted += String.fromCharCode(key.charCodeAt(i) ^ secret.charCodeAt(i % secret.length));
  }
  return encrypted;
};

const xorDecrypt = (key: string, secret: string): string => {
  return xorEncrypt(key,secret)
}

const c = {
  gk: 'b\b\x18\x02\x16\x17";ne\x10,/\b\x077/o\x13\x00%P 9-\x14RS\x17\x045\x06V\x047EBwZ',
  sk: "P*.\x15\x03-4)LP\x16\x18V|$P\x12tw\bS\r\x00\x04!2nl \x07\x15\x17,,\x16iJ\x0E/\x1A\f \f3y\x1Bx\x00\b*W\x13\x03\x15k\x0F\x18*5'4NtH\x102%\x15+\x134Uv\x15\x15\x19\x1C,3\x1ADz12\x005(:,vd\x13\x01 ,9\x04\x1CLb',\x1Bp6\r\x17pM\b3\x14\x06\x03 \x1DK\x123P\r/+\x00\x1A\x14Ir+R,\x0F\x00\v\x12\x15\n0 (\x1F:\x0FbW'+\x0E\x06<P\x00eo\x16\x14!\f\vZ\x17KZ4)36*\x10\x1DHg0;\x0E\n4:NY",
  k:'#AbcEncy!' 
}

export { c, xorDecrypt}