/* eslint-disable @typescript-eslint/no-unused-vars */
import { SerializedData } from 'bernie-core';
import { Store } from 'bernie-plugin-mobx';
import { Logger, NOOP_LOGGER } from 'bernie-logger';
import { action, makeObservable, observable } from 'mobx';
import { SanityApiSource } from '../sources';
import { checkValue } from 'src/components/functions';
import SanityApiSourceSingleton from './helpers/sanityApiSourceSingleton';
import {
  Batch1TemplateService,
  Batch2TemplateService,
  Batch3TemplateService,
  VisitUsaTemplateService,
  MagazineTemplateService,
} from './sanity';

export class SanityStore extends Store {
  @observable public campaignName: string = undefined;
  @observable public sanityPageData: any = undefined;
  @observable public isHideThreeUp: boolean = false;

  private batch1TemplateService: Batch1TemplateService;
  private batch2TemplateService: Batch2TemplateService;
  private batch3TemplateService: Batch3TemplateService;
  private visitUsaTemplateService: VisitUsaTemplateService;
  private magazineTemplateService: MagazineTemplateService;

  public constructor(
    private sanityApiSource: SanityApiSource,
    state: SerializedData = {},
    logger: Logger = NOOP_LOGGER
  ) {
    super(state, logger);
    SanityApiSourceSingleton.setInstance(this.sanityApiSource);
    this.batch1TemplateService = new Batch1TemplateService();
    this.batch2TemplateService = new Batch2TemplateService();
    this.batch3TemplateService = new Batch3TemplateService();
    this.visitUsaTemplateService = new VisitUsaTemplateService();
    this.magazineTemplateService = new MagazineTemplateService();
    makeObservable(this);
  }

  public hydrate(data: SerializedData): void {
    delete data.sanityApiSource;
    Object.assign(this, data);
  }

  // Define the setMcicd method
  public setMcicd(templateName: string, feedName: string): any {
    // Assuming mcicd is a function that returns some value
    let param1 = 'cplite';
    let param2 = templateName;
    if (checkValue(feedName)) {
      param1 = templateName;
      param2 = feedName;
    }

    return 'mcicid=cp.' + param1 + '.' + param2;
  }

  public setAdUnits(pageData): any {
    // Filter for ad units with placement 'Middle'
    const adUnitsMiddle = pageData?.content?.filter(
      (module) => module.module !== 'v3AdUnits' || (module.module === 'v3AdUnits' && module.pattern !== 'Leaderboard')
    );

    // Filter out ad units where placement is not 'Middle'
    const adUnitsNotMiddle = pageData?.content?.filter(
      (module) => module.module === 'v3AdUnits' && module.pattern === 'Leaderboard'
    );

    // Create the updated pageData object
    const updatedPageData = {
      ...pageData, // Keep other existing properties of pageData
      content: adUnitsMiddle, // Replace content with ad units that have 'Middle' placement
      adUnitsNotMiddle, // Add new property for ad units with placement other than 'Middle'
    };
    return updatedPageData; // Return the updated pageData
  }

  @action
  public async fetchSanityPageData(
    partner: string,
    templateName: string,
    feedName: string,
    pagelang: string,
    siteid: string,
    isCacheFeed: boolean,
    referrerId?: string,
    isDummyData?: boolean,
    batchQuery = 'b1',
    busaFilter?: string,
    brand?: string
  ): Promise<any> {
    let pageData = undefined;

    if (this.sanityPageData === undefined) {
      // Logic for fetching data from Sanity
      // cmslite and spotlight are the only valid values for the batchQuery parameter
      const dataFetchServiceMatcher = {
        b1: () => this.batch1TemplateService.fetchAllBatch1Template(partner, templateName, isDummyData),
        b2: () => this.batch2TemplateService.fetchAllBatch2Template(partner, templateName, isDummyData),
        b3: () => this.batch3TemplateService.fetchAllBatch3Template(partner, templateName, isDummyData),
        busa: () =>
          checkValue(feedName)
            ? this.visitUsaTemplateService.fetchVisitUsaSubTemplate(feedName, pagelang, isDummyData, busaFilter)
            : this.visitUsaTemplateService.fetchVisitUsaHomeTemplate(partner, templateName, pagelang, isDummyData),
        magazine: () =>
          checkValue(feedName)
            ? this.magazineTemplateService.fetchMagazineArticleTemplate(partner, templateName, feedName, isDummyData, pagelang, brand)
            : this.magazineTemplateService.fetchMagazineHomeTemplate(partner, templateName, isDummyData, pagelang, brand),
      };

      if (dataFetchServiceMatcher[batchQuery]) {
        pageData = await dataFetchServiceMatcher[batchQuery]();
      } else {
        pageData = [];
      }

      //Setup mcicid
      const mcicd = this.setMcicd(templateName, feedName);
      const pageDataWithAdUnits = this.setAdUnits(pageData);

      this.sanityPageData = {
        ...pageDataWithAdUnits,
        tracking: {
          mcicid: mcicd,
          partner: partner,
          templateName: templateName,
          feedName: feedName,
          pageLang: pagelang,
          batchQuery: batchQuery,
          siteid: siteid,
        },
      };
      this.campaignName = 'v3template';
    } else {
      pageData = this.sanityPageData;
    }
    return pageData;
  }

  @action
  public async hideThreeUpCard(isHideThreeUp: boolean): Promise<any> {
    this.isHideThreeUp = isHideThreeUp;
  }
}
