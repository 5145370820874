import { SerializedData } from 'bernie-core';
import { Store } from 'bernie-plugin-mobx';
import { Logger, NOOP_LOGGER } from 'bernie-logger';
import { action, observable } from 'mobx';
import { CpCmsLiteSource } from '../sources/cp-cmslite-source';
import { appendMcicid, checkValue, randmolyShuffledArray } from '../components/common/helper';
import { ConciergePageDataStore } from './types.d';
import { getTemplateType } from 'src/utils/store-utils';
import { setGlobalHeroData } from './helpers/setGlobalHeroData';
import { spotlightDataMap, accelerateDataMap } from './helpers/dataMap';
import { get } from 'lodash';
import { templateMachetes } from './helpers/templateMachater';

interface filterPops {
  filterType: string;
  filterValue: string;
}
export class CpCmsLiteStore extends Store {
  @observable public campaignName: string = undefined;
  @observable public cpCmsLitePageData: any = undefined;
  @observable public spotlightPageData: any = undefined;
  @observable public conciergePageData: ConciergePageDataStore = undefined;
  @observable public testPageData: any = undefined;
  @observable public isCpCmsLiteCampaign: any = undefined;
  @observable public stopoverSearchData: any = undefined;
  @observable public extraTrackingParam: any = undefined;
  @observable public pageModuleVersion: any = undefined;

  public constructor(
    private cpCmsLiteSource: CpCmsLiteSource,
    state: SerializedData = {},
    logger: Logger = NOOP_LOGGER
  ) {
    super(state, logger);
  }

  public hydrate(data: SerializedData): void {
    delete data.cpCmsLiteSource;
    Object.assign(this, data);
  }

  @action
  public async fetchFeedPageData(
    partner: string,
    templateName: string,
    feedName: string,
    pagelang: string,
    siteid: string,
    isCacheFeed: boolean,
    referrerId?: string
  ): Promise<any> {
    return this.fetchCpCmsLiteFeedPageData(partner, templateName, feedName, pagelang, siteid, isCacheFeed, referrerId);
  }

  @action
  public async fetchCpCmsLiteFeedPageData(
    partner: string,
    templateName: string,
    feedName: string,
    pagelang: string,
    siteid: string,
    isCacheFeed: boolean,
    referrerId?: string
  ): Promise<any> {
    let cpCmsLiteFetchedData = undefined;
    let templateType = '';

    if (this.isCpCmsLiteCampaign === undefined) {
      cpCmsLiteFetchedData = await this.cpCmsLiteSource
        .fetchCpCMSLitePageData(templateName, feedName, pagelang, siteid, isCacheFeed)
        .then((data) => data);

      // Check campaign is available
      if (typeof cpCmsLiteFetchedData === 'string' && cpCmsLiteFetchedData === 'campaign not found') {
        return cpCmsLiteFetchedData;
      }
      this.testPageData = undefined;
      const cmsTemplate = cpCmsLiteFetchedData.template;

      const templateMapper = getTemplateType(cmsTemplate);

      cpCmsLiteFetchedData.referrerId = referrerId;
      cpCmsLiteFetchedData.mainUrl = templateName;

      // Override the TemplateType by getting the help of the template mapper
      // Not consider the api templateType parameter any more.
      cpCmsLiteFetchedData.templateType = templateMapper?.templateType;
      cpCmsLiteFetchedData.template = templateMapper?.template;
      cpCmsLiteFetchedData.templateCampaignName = cmsTemplate;
      switch (templateMapper?.templateType) {
        case 'spotlight': {
          // Same Spotlight page template are share with sports and media studio
          // So base on the partner we have to switch with page to loade
          let pageName = 'spotlight';

          // This is use to generate the sub header of the media studio
          if (partner === 'media-studio') {
            // Set media studio page name
            pageName = 'media-studio';

            // Add navigation for the media studio
            cpCmsLiteFetchedData = {
              ...cpCmsLiteFetchedData,
              msdata: {
                navigation: [
                  {
                    title: 'Abu Dhabi',
                    url: '/mstudiodev/video/abu-dhabi-vid0?mcicid=cp.cplite.mstudiodev',
                    urlFriendlyName: 'abu-dhabi',
                  },
                  {
                    title: 'Panamá',
                    url: '/mstudiodev/channel/panama?mcicid=cp.cplite.mstudiodev',
                    urlFriendlyName: 'panama',
                  },
                ],
                subPages: [],
              },
            };
          }

          // TNC page comes with the TNC
          if (feedName.toLowerCase().startsWith('tnc-')) {
            pageName = 'tnc';
          }

          this.setSpotLightPageData(cpCmsLiteFetchedData);
          // this.setCpCmsLitePageData(cpCmsLiteFetchedData);

          this.setName(pageName);
          templateType = 'spotlight';
          break;
        }
        case 'cplite': {
          this.setCpCmsLitePageData(cpCmsLiteFetchedData);
          if (
            cpCmsLiteFetchedData?.urlPattern === 'football' ||
            cpCmsLiteFetchedData?.urlPattern === 'see' ||
            cpCmsLiteFetchedData?.urlPattern === 'acc'
          ) {
            // Accelarate template and check the template is ttnqOneoff
            // If so set the name to ttnqOneoff
            // Default template is football
            if (templateMapper?.template === 'ttnqOneoff') {
              this.setName('ttnqOneoff');
            } else {
              this.setName('football');
            }
          } else if (cpCmsLiteFetchedData?.urlPattern === 'media-studio') {
            this.setMediaData(this.cpCmsLitePageData, templateName, partner);
            this.setName('media-studio');
          }
          templateType = 'cpCmsLite';
          break;
        }
        case 'concierge': {
          this.setConciergePageData(cpCmsLiteFetchedData);
          //this.setCpCmsLiteMultipleHotelDeals(cpCmsLiteFetchedData);
          this.setName('concierge');
          templateType = 'cpCmsLite';
          break;
        }
        default: {
          this.setCpCmsLitePageData(cpCmsLiteFetchedData);
          //this.setCpCmsLiteMultipleHotelDeals(cpCmsLiteFetchedData);
          this.setName('accelerate');
          templateType = 'cpCmsLite';
        }
      }
      this.setIsCpCmsLiteCampaign('cpcmslitecampain');
    } else {
      switch (this.campaignName) {
        case 'spotlight': {
          cpCmsLiteFetchedData = this.spotlightPageData;
          templateType = 'spotlight';
          break;
        }
        default: {
          // By default cmp lite home
          cpCmsLiteFetchedData = this.cpCmsLitePageData;
          templateType = 'cpCmsLite';
          break;
        }
      }
    }
    // THis used to set the hero data
    this.extraTrackingParam = cpCmsLiteFetchedData?.extraTrackingParam;
    cpCmsLiteFetchedData = this.setQuizWithEntryForm(cpCmsLiteFetchedData);
    cpCmsLiteFetchedData = this.setGlobalMcicds(cpCmsLiteFetchedData);
    cpCmsLiteFetchedData = this.setGlobalHero(cpCmsLiteFetchedData, templateType);
    cpCmsLiteFetchedData = this.setLozengeMessage(cpCmsLiteFetchedData);
    cpCmsLiteFetchedData = this.setRandomizedArticleData(cpCmsLiteFetchedData, templateType);
    cpCmsLiteFetchedData = this.setPageModuleVersion(cpCmsLiteFetchedData);

    return cpCmsLiteFetchedData;
  }

  @action
  public setQuizWithEntryForm(storeData) {
    if (checkValue(storeData?.pageContent)) {
      let quizCount = 0;
      let integrateWithEntryFormTrueCount = 0;
      let entryFormModule = null;
      const entryModuleMap = new Map();

      for (const obj of storeData.pageContent) {
        if (obj.module === 'entryFormModule') {
          entryModuleMap.set(obj.uniquekey, obj);
        }
        if (obj.module === 'quizModuleNew') {
          quizCount++;
          if (obj.integrateWithEntryForm === 'True') {
            integrateWithEntryFormTrueCount++;
            entryFormModule = obj;
          }
        }
      }

      if (quizCount === 1 && integrateWithEntryFormTrueCount === 1 && entryFormModule && entryModuleMap.size === 1) {
        entryFormModule.entryFormModule = { data: { ...entryModuleMap.values().next().value, isQuiz: true } };
        storeData.pageContent = storeData.pageContent.filter(
          (item: { module: string }) => item.module !== 'entryFormModule'
        );
      }
    }
    return storeData;
  }

  @action
  public fetchCpCmsLiteTestPageData() {
    this.setName('test');
    this.testPageData = {};
  }

  @action
  public fetchCpdsTestPageData() {
    this.setName('cpds-demo');
    this.testPageData = {};
  }

  @action
  public setName(name) {
    this.campaignName = name;
  }

  @action
  public setCpCmsLitePageData(cpCmsLitePageData) {
    this.cpCmsLitePageData = this.setCpCmsLiteMcicids(cpCmsLitePageData);
    // This code required if we go with multi hotels deals module
    //this.cpCmsLitePageData = this.setCpCmsLiteMultipleHotelDeals(this.cpCmsLitePageData);
  }

  @action
  public setSpotLightPageData(spotlightPageData) {
    this.spotlightPageData = this.setSpotLightMcicids(spotlightPageData);
  }

  @action
  public setConciergePageData(conciergePageData) {
    this.conciergePageData = this.setConciergeMcicids(conciergePageData);
    this.conciergePageData = { ...this.conciergePageData, data: conciergePageData };
  }

  @action
  public setIsCpCmsLiteCampaign(isCpCmsLiteCampaign) {
    this.isCpCmsLiteCampaign = isCpCmsLiteCampaign;
  }

  /**
   *
   * @param filter
   * Concierge actions
   */

  // This function adds mcicid parameters for the specific properties in CP Lite Data
  private setCpCmsLiteMcicids(cpCmsLitePageData) {
    const templateType = cpCmsLitePageData.templateType;
    const campaignUrl = cpCmsLitePageData.mainUrl;
    const referrerId = cpCmsLitePageData.referrerId;
    const mainUrl = cpCmsLitePageData?.mainUrl;
    const extraTacking = cpCmsLitePageData?.extraTrackingParam;
    if (cpCmsLitePageData.bookNowCTALink && cpCmsLitePageData.bookNowCTALink != '') {
      cpCmsLitePageData.bookNowCTALink = appendMcicid(
        cpCmsLitePageData.bookNowCTALink,
        templateType,
        campaignUrl,
        mainUrl,
        referrerId,
        extraTacking
      );
    }

    if (cpCmsLitePageData.partnerUrl && cpCmsLitePageData.partnerUrl != '') {
      cpCmsLitePageData.partnerUrl = appendMcicid(
        cpCmsLitePageData.partnerUrl,
        templateType,
        campaignUrl,
        mainUrl,
        referrerId,
        extraTacking
      );
    }

    if (cpCmsLitePageData.partnerMessageUrl && cpCmsLitePageData.partnerMessageUrl != '') {
      cpCmsLitePageData.partnerMessageUrl = appendMcicid(
        cpCmsLitePageData.partnerMessageUrl,
        templateType,
        campaignUrl,
        mainUrl,
        referrerId,
        extraTacking
      );
    }

    if (cpCmsLitePageData.partnerCtaUrl && cpCmsLitePageData.partnerCtaUrl != '') {
      cpCmsLitePageData.partnerCtaUrl = appendMcicid(
        cpCmsLitePageData.partnerCtaUrl,
        templateType,
        campaignUrl,
        mainUrl,
        referrerId,
        extraTacking
      );
    }

    if (cpCmsLitePageData.content) {
      for (let i = 0; i < cpCmsLitePageData.content.length; i++) {
        const content = cpCmsLitePageData.content[i];
        if (content.cTALink && content.cTALink != '') {
          content.cTALink = appendMcicid(content.cTALink, templateType, campaignUrl, mainUrl, referrerId, extraTacking);
        }

        if (content.cTAUrl && content.cTAUrl != '') {
          content.cTAUrl = appendMcicid(content.cTAUrl, templateType, campaignUrl, mainUrl, referrerId, extraTacking);
        }

        if (content.link1Url && content.link1Url != '') {
          content.link1Url = appendMcicid(
            content.link1Url,
            templateType,
            campaignUrl,
            mainUrl,
            referrerId,
            extraTacking
          );
        }

        if (content.link2Url && content.link2Url != '') {
          content.link2Url = appendMcicid(
            content.link2Url,
            templateType,
            campaignUrl,
            mainUrl,
            referrerId,
            extraTacking
          );
        }

        if (content.contentItems) {
          for (let j = 0; j < content.contentItems.length; j++) {
            const contentItem = content.contentItems[j];
            if (contentItem.contentItem) {
              for (let k = 0; k < contentItem.contentItem.length; k++) {
                const subContentItem = contentItem.contentItem[k];
                if (subContentItem.url && subContentItem.url != '')
                  subContentItem.url = appendMcicid(
                    subContentItem.url,
                    templateType,
                    campaignUrl,
                    mainUrl,
                    referrerId,
                    extraTacking
                  );
              }
            }

            if (contentItem.contentUrl && contentItem.contentUrl != '')
              contentItem.contentUrl = appendMcicid(
                contentItem.contentUrl,
                templateType,
                campaignUrl,
                mainUrl,
                referrerId,
                extraTacking
              );

            if (contentItem.cTAUrl && contentItem.cTAUrl != '')
              contentItem.cTAUrl = appendMcicid(
                contentItem.cTAUrl,
                templateType,
                campaignUrl,
                mainUrl,
                referrerId,
                extraTacking
              );

            if (contentItem.contentSubpage) {
              for (let k = 0; k < contentItem.contentSubpage.length; k++) {
                const contentSubPageItem = contentItem.contentSubpage[k];
                if (contentSubPageItem.url && contentSubPageItem.url != '')
                  contentSubPageItem.url = appendMcicid(
                    contentSubPageItem.url,
                    templateType,
                    campaignUrl,
                    mainUrl,
                    referrerId,
                    extraTacking
                  );
              }
            }
          }
        }

        if (content.productList) {
          for (let j = 0; j < content.productList.length; j++) {
            const productListItem = content.productList[j];
            if (productListItem.cTALink && productListItem.cTALink != '')
              productListItem.cTALink = appendMcicid(
                productListItem.cTALink,
                templateType,
                campaignUrl,
                mainUrl,
                referrerId,
                extraTacking
              );
          }
        }

        if (content.contentTilesNew) {
          for (let j = 0; j < content.contentTilesNew.length; j++) {
            const contentTile = content.contentTilesNew[j];
            if (contentTile.tileSubpage) {
              for (let k = 0; k < contentTile.tileSubpage.length; k++) {
                const tileSubPage = contentTile.tileSubpage[k];
                if (tileSubPage.url && tileSubPage.url != '')
                  tileSubPage.url = appendMcicid(
                    tileSubPage.url,
                    templateType,
                    campaignUrl,
                    mainUrl,
                    referrerId,
                    extraTacking
                  );
              }
            }

            if (contentTile.tileUrl && contentTile.tileUrl != '') {
              contentTile.tileUrl = appendMcicid(
                contentTile.tileUrl,
                templateType,
                campaignUrl,
                mainUrl,
                referrerId,
                extraTacking
              );
            }
          }
        }

        if (content.contentImages) {
          for (let j = 0; j < content.contentImages.length; j++) {
            const contentTile = content.contentImages[j];
            if (contentTile.linkToPage && contentTile.linkToPage != '')
              contentTile.linkToPage = appendMcicid(
                contentTile.linkToPage,
                templateType,
                campaignUrl,
                mainUrl,
                referrerId,
                extraTacking
              );
          }
        }

        if (content.galleryItems) {
          for (let j = 0; j < content.galleryItems.length; j++) {
            const contentTile = content.galleryItems[j];
            if (contentTile.cTALink && contentTile.cTALink != '')
              contentTile.cTALink = appendMcicid(
                contentTile.cTALink,
                templateType,
                campaignUrl,
                mainUrl,
                referrerId,
                extraTacking
              );
          }
        }

        if (checkValue(content?.galleryMediaItems)) {
          for (let k = 0; k < content.galleryMediaItems.length; k++) {
            const galleryItem = content.galleryMediaItems[k];
            if (checkValue(galleryItem) && checkValue(galleryItem.cTALink)) {
              galleryItem.cTALink = appendMcicid(
                galleryItem.cTALink,
                templateType,
                campaignUrl,
                mainUrl,
                referrerId,
                extraTacking
              );
            }
          }
        }
        if (checkValue(content.articleList, true)) {
          for (let k = 0; k < content.articleList.length; k++) {
            const article = content.articleList[k];
            if (checkValue(article) && checkValue(article.ctaUrl)) {
              article.ctaUrl = appendMcicid(
                article.ctaUrl,
                templateType,
                campaignUrl,
                mainUrl,
                referrerId,
                extraTacking
              );
            }
          }
        }
        if (checkValue(content?.tCUrl)) {
          content.tCUrl = appendMcicid(content.tCUrl, templateType, campaignUrl, mainUrl, referrerId, extraTacking);
        }
        if (checkValue(content?.carouselItems, true)) {
          for (let k = 0; k < content.carouselItems.length; k++) {
            const carouselItem = content.carouselItems[k];
            if (checkValue(carouselItem) && checkValue(carouselItem.cTAUrl)) {
              carouselItem.cTAUrl = appendMcicid(
                carouselItem.cTAUrl,
                templateType,
                campaignUrl,
                mainUrl,
                referrerId,
                extraTacking
              );
            }
          }
        }
        if (checkValue(content?.itemList, true)) {
          for (let k = 0; k < content.itemList.length; k++) {
            const item = content.itemList[k];
            if (checkValue(item) && checkValue(item.ctaUrl)) {
              item.ctaUrl = appendMcicid(item.ctaUrl, templateType, campaignUrl, mainUrl, referrerId, extraTacking);
            }
          }
        }
        if (checkValue(content?.filterModuleList, true)) {
          for (let k = 0; k < content.filterModuleList.length; k++) {
            const item = content.filterModuleList[k];
            if (checkValue(item?.productList, true)) {
              for (let l = 0; l < item.productList.length; l++) {
                const prod = item.productList[l];
                if (checkValue(prod) && checkValue(prod.cTALink)) {
                  prod.cTALink = appendMcicid(
                    prod.cTALink,
                    templateType,
                    campaignUrl,
                    mainUrl,
                    referrerId,
                    extraTacking
                  );
                }
              }
            }
          }
        }
      }
    }

    if (cpCmsLitePageData.hotelDeals) {
      for (let i = 0; i < cpCmsLitePageData.hotelDeals.length; i++) {
        const hotelDeal = cpCmsLitePageData.hotelDeals[i];
        if (hotelDeal.url && hotelDeal.url != '') {
          hotelDeal.url = appendMcicid(hotelDeal.url, templateType, campaignUrl, mainUrl, referrerId, extraTacking);
        }
      }
    }

    if (cpCmsLitePageData.socialModule) {
      for (let i = 0; i < cpCmsLitePageData.socialModule.length; i++) {
        const socialModule = cpCmsLitePageData.socialModule[i];
        if (socialModule.link && socialModule.link != '') {
          socialModule.link = appendMcicid(
            socialModule.link,
            templateType,
            campaignUrl,
            mainUrl,
            referrerId,
            extraTacking
          );
        }
      }
    }

    return cpCmsLitePageData;
  }

  // This function adds mcicid parameters for the specific properties in Spotlight Data
  private setSpotLightMcicids(spotlightPageData) {
    const templateType = spotlightPageData?.templateType;
    const campaignUrl = spotlightPageData?.campaignUrl;
    const referrerId = spotlightPageData?.referrerId;
    const mainUrl = spotlightPageData?.mainUrl;
    const extraTacking = spotlightPageData?.extraTrackingParam;

    if (spotlightPageData.partnerUrl && spotlightPageData.partnerUrl != '') {
      spotlightPageData.partnerUrl = appendMcicid(
        spotlightPageData.partnerUrl,
        templateType,
        campaignUrl,
        mainUrl,
        referrerId,
        extraTacking
      );
    }

    if (spotlightPageData.partnerMessageUrl && spotlightPageData.partnerMessageUrl != '') {
      spotlightPageData.partnerMessageUrl = appendMcicid(
        spotlightPageData.partnerMessageUrl,
        templateType,
        campaignUrl,
        mainUrl,
        referrerId,
        extraTacking
      );
    }

    if (spotlightPageData.bookNowCTALink && spotlightPageData.bookNowCTALink != '') {
      spotlightPageData.bookNowCTALink = appendMcicid(
        spotlightPageData.bookNowCTALink,
        templateType,
        campaignUrl,
        mainUrl,
        referrerId,
        extraTacking
      );
    }

    if (spotlightPageData.partnerCtaUrl && spotlightPageData.partnerCtaUrl != '') {
      spotlightPageData.partnerCtaUrl = appendMcicid(
        spotlightPageData.partnerCtaUrl,
        templateType,
        campaignUrl,
        mainUrl,
        referrerId,
        extraTacking
      );
    } else {
      spotlightPageData.partnerCtaUrl = spotlightPageData.bookNowCTALink;
    }

    if (!checkValue(spotlightPageData.partnerCtaText) && checkValue(spotlightPageData.bookNowCTACopy)) {
      spotlightPageData.partnerCtaText = spotlightPageData.bookNowCTACopy;
    }

    if (spotlightPageData.socialModule) {
      for (let i = 0; i < spotlightPageData.socialModule.length; i++) {
        const socialModule = spotlightPageData.socialModule[i];
        if (socialModule.link && socialModule.link != '') {
          socialModule.link = appendMcicid(
            socialModule.link,
            templateType,
            campaignUrl,
            mainUrl,
            referrerId,
            extraTacking
          );
        }
      }
    }

    if (spotlightPageData.pageContent) {
      for (let i = 0; i < spotlightPageData.pageContent.length; i++) {
        const contentItem = spotlightPageData.pageContent[i];
        if (contentItem.link1Url && contentItem.link1Url != '')
          contentItem.link1Url = appendMcicid(
            contentItem.link1Url,
            templateType,
            campaignUrl,
            mainUrl,
            referrerId,
            extraTacking
          );
        if (contentItem.link2Url && contentItem.link2Url != '')
          contentItem.link2Url = appendMcicid(
            contentItem.link2Url,
            templateType,
            campaignUrl,
            mainUrl,
            referrerId,
            extraTacking
          );

        if (contentItem.contentTilesNew) {
          for (let j = 0; j < contentItem.contentTilesNew.length; j++) {
            const contentTilesNew = contentItem.contentTilesNew[j];
            if (contentTilesNew.tileUrl && contentTilesNew.tileUrl != '') {
              contentTilesNew.tileUrl = appendMcicid(
                contentTilesNew.tileUrl,
                templateType,
                campaignUrl,
                mainUrl,
                referrerId,
                extraTacking
              );
            }
          }
        }

        if (contentItem.contentImages) {
          for (let j = 0; j < contentItem.contentImages.length; j++) {
            const contentTile = contentItem.contentImages[j];
            if (contentTile.linkToPage && contentTile.linkToPage != '')
              contentTile.linkToPage = appendMcicid(
                contentTile.linkToPage,
                templateType,
                campaignUrl,
                mainUrl,
                referrerId,
                extraTacking
              );
          }
        }

        if (contentItem.productList) {
          for (let k = 0; k < contentItem.productList.length; k++) {
            const productList = contentItem.productList[k];
            if (productList.cTALink && productList.cTALink != '') {
              productList.cTALink = appendMcicid(
                productList.cTALink,
                templateType,
                campaignUrl,
                mainUrl,
                referrerId,
                extraTacking
              );
            }
          }
        }

        if (contentItem.cTALink && contentItem.cTALink != '') {
          contentItem.cTALink = appendMcicid(
            contentItem.cTALink,
            templateType,
            campaignUrl,
            mainUrl,
            referrerId,
            extraTacking
          );
        }

        if (contentItem.cTAUrl && contentItem.cTAUrl != '') {
          contentItem.cTAUrl = appendMcicid(
            contentItem.cTAUrl,
            templateType,
            campaignUrl,
            mainUrl,
            referrerId,
            extraTacking
          );
        }

        if (checkValue(contentItem.logoList)) {
          for (let k = 0; k < contentItem.logoList.length; k++) {
            const logoList = contentItem.logoList[k];
            if (checkValue(logoList) && checkValue(logoList.partnerUrl)) {
              logoList.partnerUrl = appendMcicid(
                logoList.partnerUrl,
                templateType,
                campaignUrl,
                mainUrl,
                referrerId,
                extraTacking
              );
            }
          }
        }

        if (checkValue(contentItem.galleryItems)) {
          for (let k = 0; k < contentItem.galleryItems.length; k++) {
            const galleryItem = contentItem.galleryItems[k];
            if (checkValue(galleryItem) && checkValue(galleryItem.cTALink)) {
              galleryItem.cTALink = appendMcicid(
                galleryItem.cTALink,
                templateType,
                campaignUrl,
                mainUrl,
                referrerId,
                extraTacking
              );
            }
          }
        }

        if (checkValue(contentItem.galleryMediaItems)) {
          for (let k = 0; k < contentItem.galleryMediaItems.length; k++) {
            const galleryItem = contentItem.galleryMediaItems[k];
            if (checkValue(galleryItem) && checkValue(galleryItem.cTALink)) {
              galleryItem.cTALink = appendMcicid(
                galleryItem.cTALink,
                templateType,
                campaignUrl,
                mainUrl,
                referrerId,
                extraTacking
              );
            }
          }
        }
        if (checkValue(contentItem?.tabItems, true)) {
          for (let k = 0; k < contentItem.tabItems.length; k++) {
            const tabItem = contentItem.tabItems[k];
            if (checkValue(tabItem) && checkValue(tabItem?.ctaUrl)) {
              tabItem.ctaUrl = appendMcicid(
                tabItem.ctaUrl,
                templateType,
                campaignUrl,
                mainUrl,
                referrerId,
                extraTacking
              );
            }
          }
        }
      }
    }

    // Get the heroModule data and push them to content object
    const heroModuleData = {
      opacitySelect: spotlightPageData?.opacitySelect,
      heroTitleDesktop: spotlightPageData?.heroTitleDesktop,
      heroTitleMobile: spotlightPageData?.heroTitleMobile,
      heroSubTextDesktop: spotlightPageData?.heroSubTextDesktop,
      heroSubTextMobile: spotlightPageData?.heroSubTextMobile,
      heroStyle: spotlightPageData?.heroStyle,
      heroImageNew: spotlightPageData?.heroImageNew,
      turnOffCropsForHeroImage: spotlightPageData?.turnOffCropsForHeroImage,
      turnOffGradient: spotlightPageData?.turnOffGradient,
      heroVideoYouTubeId: spotlightPageData?.heroVideoYouTubeId,
      heroVideo: spotlightPageData?.heroVideo || spotlightPageData?.backgroundVideoFile,
      heroVideoPosition: spotlightPageData?.heroVideoPosition || 'Background video',
      heroVideoWidth: spotlightPageData?.heroVideoWidth,
      heroVideoHeight: spotlightPageData?.heroVideoHeight,
      heroVideoImage: spotlightPageData?.heroVideoImageNew,
      rectangleBarColorLeft: spotlightPageData?.barColorLeft,
      rectangleBarColorRight: spotlightPageData?.barColorRight,
      heroImageCaption: spotlightPageData?.heroImageCaption,
      heroImageCopyright: spotlightPageData?.heroImageCopyright,
      heroImageDescription: spotlightPageData?.heroImageDescription,
      heroLogo: spotlightPageData?.heroLogo,
      heroLogoStyle: spotlightPageData?.heroLogoStyle,
      heroImage: spotlightPageData?.heroImage,
      heroOverlayImageDesktop: spotlightPageData?.heroOverlayImageDesktop,
      heroOverlayImageMobile: spotlightPageData?.heroOverlayImageMobile,
      textColor: spotlightPageData?.textColor,
      module: 'heroModuleSpotlight',
    };
    if (
      checkValue(spotlightPageData?.heroImageNew) ||
      checkValue(spotlightPageData?.heroVideoYouTubeId) ||
      checkValue(spotlightPageData?.heroVideo) ||
      checkValue(spotlightPageData?.heroImage)
    ) {
      if (checkValue(spotlightPageData?.pageContent, true)) {
        if (!spotlightPageData?.pageContent?.some((el) => el.module.includes('heroModuleSpotlight'))) {
          spotlightPageData.pageContent.unshift(heroModuleData);
        }
      } else {
        spotlightPageData.pageContent = [];
        spotlightPageData?.pageContent.push(heroModuleData);
      }
    }

    if (spotlightPageData.galleryImages) {
      for (let i = 0; i < spotlightPageData.galleryImages.length; i++) {
        const galleryItem = spotlightPageData.galleryImages[i];
        if (galleryItem.linkUrl && galleryItem.linkUrl != '')
          galleryItem.linkUrl = appendMcicid(
            galleryItem.linkUrl,
            templateType,
            campaignUrl,
            mainUrl,
            referrerId,
            extraTacking
          );
      }
    }

    if (spotlightPageData.customCTALink && spotlightPageData.customCTALink != '')
      spotlightPageData.customCTALink = appendMcicid(
        spotlightPageData.customCTALink,
        templateType,
        campaignUrl,
        mainUrl,
        referrerId,
        extraTacking
      );

    if (spotlightPageData.results) {
      for (let i = 0; i < spotlightPageData.results.length; i++) {
        const resultItem = spotlightPageData.results[i];
        if (resultItem.customCTALink && resultItem.customCTALink != '')
          resultItem.customCTALink = appendMcicid(
            resultItem.customCTALink,
            templateType,
            campaignUrl,
            mainUrl,
            referrerId,
            extraTacking
          );
      }
    }

    if (spotlightPageData.successfulCTALink && spotlightPageData.successfulCTALink != '')
      spotlightPageData.successfulCTALink = appendMcicid(
        spotlightPageData.successfulCTALink,
        templateType,
        campaignUrl,
        mainUrl,
        referrerId,
        extraTacking
      );

    //remap the spotlight (meso 2 projects) wizard data to content
    const wizardItems = {
      title: '',
      uniquekey: 'content-wizard',
      wizardTabs: spotlightPageData?.wizardTabs,
      defaultTab: spotlightPageData?.defaultTab,
      wizardUrlOverride: spotlightPageData?.wizardUrlOverride,
      departureDefault: spotlightPageData?.departureDefault,
      returnDefault: spotlightPageData?.arrivalDefault,
      departureDate: spotlightPageData?.departureDate,
      returnDate: spotlightPageData?.returnDate,
      departureDateFixed: spotlightPageData?.departureDateFixed,
      returnDateFixed: spotlightPageData?.returnDateFixed,
      optionalTermsConditions: spotlightPageData?.optionalTermsConditions,
      endOfPageCopy: spotlightPageData?.endOfPageCopy,
      module: 'bookingWidget',
      cTAColor: spotlightPageData?.cTAColor,
      cTATextColor: spotlightPageData?.cTATextColor,
      moduleTextColor: spotlightPageData?.moduleTextColor,
      languageSelect: spotlightPageData?.languageSelect,
    };

    const leagalTextModuleData = {
      copy: spotlightPageData?.optionalTermsConditions,
      copyColor: '#000000',
      module: 'legalTextModule',
    };

    const contentModules = spotlightPageData?.pageContent;

    if (contentModules && contentModules.length > 0) {
      //check whether content already has a booking widget
      const isBookingWidget = contentModules.filter((content) => content?.module === 'bookingWidget');
      //check whether content already has a leagalText module
      const isLegalTextModule = contentModules.filter((content) => content?.module === 'legalTextModule');

      if (isBookingWidget.length < 1) {
        contentModules.push(wizardItems);
      }
      if (isLegalTextModule.length < 1) {
        contentModules.push(leagalTextModuleData);
      }
    } else {
      spotlightPageData = { ...spotlightPageData, pageContent: [wizardItems, leagalTextModuleData] };
    }

    return spotlightPageData;
  }

  // This function adds mcicid parameters for the specific properties in Spotlight Data
  private setConciergeMcicids(conciergePageData) {
    const templateType = conciergePageData.templateType;
    const campaignUrl = conciergePageData.mainUrl;
    const referrerId = conciergePageData.referrerId;
    const extraTacking = conciergePageData?.extraTrackingParam;

    if (conciergePageData.bookNowCtaLink && conciergePageData.bookNowCtaLink != '') {
      conciergePageData.bookNowCtaLink = appendMcicid(
        conciergePageData.bookNowCtaLink,
        templateType,
        campaignUrl,
        referrerId,
        extraTacking
      );
    }

    if (conciergePageData.partnerUrl && conciergePageData.partnerUrl != '') {
      conciergePageData.partnerUrl = appendMcicid(
        conciergePageData.partnerUrl,
        templateType,
        campaignUrl,
        referrerId,
        extraTacking
      );
    }

    if (conciergePageData.cTALink && conciergePageData.cTALink != '') {
      conciergePageData.cTALink = appendMcicid(
        conciergePageData.cTALink,
        templateType,
        campaignUrl,
        referrerId,
        extraTacking
      );
    }

    if (conciergePageData.headerCtaUrl && conciergePageData.headerCtaUrl != '') {
      conciergePageData.headerCtaUrl = appendMcicid(
        conciergePageData.headerCtaUrl,
        templateType,
        campaignUrl,
        referrerId,
        extraTacking
      );
    }

    const categoryItems = [];
    const categoryFilters = [];

    // Explore Content are coming inside the content
    const contentModules = conciergePageData.content;
    let filterType = 'v2';
    if (contentModules && contentModules.length > 0) {
      for (let i = 0; i < contentModules.length; i++) {
        const content = contentModules?.[i];
        if (content.module === 'tileFilterModule') {
          if (checkValue(content?.filterStyle) && content?.filterStyle?.toLowerCase() === 'style 1 - dropdown') {
            filterType = 'v1';
          }

          if (content && content.exploreContentItems && content.exploreContentItems.length > 0) {
            const exploreContentItems = content.exploreContentItems;

            exploreContentItems.map((expItem) => {
              if (expItem.cTALink && expItem.cTALink != '') {
                expItem.cTALink = appendMcicid(expItem.cTALink, templateType, campaignUrl, referrerId, extraTacking);
              }
              expItem.uniquekey = expItem?.uniquekey?.replace('exploreContentItems-', 'eci');

              const expCategoryName = expItem?.category[0]?.name;
              if (categoryItems.length > 0) {
                const hasCategoryItem = categoryItems.find((catItem) => catItem.categoryName === expCategoryName);
                if (typeof hasCategoryItem !== 'undefined') {
                  hasCategoryItem.categoryItems.push({ id: expItem.uniquekey, data: expItem });
                } else {
                  categoryItems.push({
                    categoryName: expCategoryName,
                    categoryItems: [{ id: expItem.uniquekey, data: expItem }],
                  });
                }
              } else {
                categoryItems.push({
                  categoryName: expCategoryName,
                  categoryItems: [{ id: expItem.uniquekey, data: expItem }],
                });
              }

              const filters = expItem?.filterItems;
              const formatFilters = [];

              if (filters && filters !== '') {
                filters?.map((f: any) => {
                  const filterCategory: string = f.folder;
                  const filterName = f.name;
                  const filterIcon = f.foldericon?.[0]?.url;
                  if (filterCategory && filterCategory !== '') {
                    if (categoryFilters.length > 0) {
                      const hasFilterCategory = categoryFilters.find(
                        (catFilter) => catFilter.filterCategoryType === filterCategory
                      );
                      if (typeof hasFilterCategory !== 'undefined') {
                        if (!hasFilterCategory.filterCategoryItems.includes(filterName)) {
                          hasFilterCategory.filterCategoryItems.push(filterName);
                        }
                      } else {
                        categoryFilters.push({
                          filterCategoryType: filterCategory,
                          filterCategoryIcon: filterIcon,
                          filterCategoryItems: [filterName],
                        });
                      }
                    } else {
                      categoryFilters.push({
                        filterCategoryType: filterCategory,
                        filterCategoryIcon: filterIcon,
                        filterCategoryItems: [filterName],
                      });
                    }

                    // order the filters
                    formatFilters.push(
                      `${filterCategory?.replace(/\s/g, '-').replace(/&/g, '-')?.toLocaleLowerCase()}:${filterName
                        ?.replace(/\s/g, '-')
                        .replace(/&/g, '-')
                        ?.toLocaleLowerCase()}`
                    );
                  }
                });

                expItem.formatFilters = formatFilters.join(',');
              }
            });
          }
        }

        if (content.module === 'itineraryModule') {
          if (content.itineraryCtaLink && content.itineraryCtaLink != '') {
            content.itineraryCtaLink = appendMcicid(
              content.itineraryCtaLink,
              templateType,
              campaignUrl,
              referrerId,
              extraTacking
            );
          }
        }

        if (content.module === 'hotelDealsModule') {
          if (content.cTAUrl && content.cTAUrl != '') {
            content.cTAUrl = appendMcicid(content.cTAUrl, templateType, campaignUrl, referrerId, extraTacking);
          }
        }
      }
    }

    // Get the wizard data and push them to content object
    const wizardItems = {
      title: '',
      uniquekey: 'content-wizard',
      wizardTabs: conciergePageData.wizardTabs,
      defaultTab: conciergePageData.defaultTab,
      wizardUrlOverride: conciergePageData.wizardUrlOverride,
      departureDefault: conciergePageData.departureDefault,
      returnDefault: conciergePageData.returnDefault,
      departureDate: conciergePageData.departureDate,
      returnDate: conciergePageData.returnDate,
      departureDateFixed: conciergePageData.departureDateFixed,
      returnDateFixed: conciergePageData.returnDateFixed,
      optionalTermsConditions: conciergePageData.optionalTermsConditions,
      endOfPageCopy: conciergePageData.endOfPageCopy,
      module: 'bookingWidget',
    };
    if (contentModules && contentModules.length > 0) {
      contentModules?.push(wizardItems);
    } else {
      conciergePageData.content = {};
      conciergePageData?.content?.push(wizardItems);
    }
    // Gallery Media Links
    if (conciergePageData.galleryMediaItems && conciergePageData.galleryMediaItems.length > 0) {
      conciergePageData.galleryMediaItems.map((galItem) => {
        if (galItem.cTALink && galItem.cTALink != '') {
          galItem.cTALink = appendMcicid(galItem.cTALink, templateType, campaignUrl, referrerId, extraTacking);
        }
      });
    }

    if (conciergePageData.itineraryCtaLink && conciergePageData.itineraryCtaLink != '') {
      conciergePageData.itineraryCtaLink = appendMcicid(
        conciergePageData.itineraryCtaLink,
        templateType,
        campaignUrl,
        referrerId,
        extraTacking
      );
    }

    // Offer Item Links
    if (conciergePageData.offerItems && conciergePageData.offerItems.length > 0) {
      conciergePageData.offerItems.map((offerItem) => {
        if (offerItem.cTAUrl && offerItem.cTAUrl != '') {
          offerItem.cTAUrl = appendMcicid(offerItem.cTAUrl, templateType, campaignUrl, referrerId, extraTacking);
        }
      });
    }

    // itinerary
    if (conciergePageData.itinerary && conciergePageData.itinerary.length > 0) {
      conciergePageData.itinerary.map((itin) => {
        const { itineraryDay1, itineraryDay2, itineraryDay3, itineraryDay4, itineraryDay5 } = itin;
        if (itineraryDay1) {
          itineraryDay1.map((d1) => {
            if (d1 && d1.cTALink && d1.cTALink != '') {
              d1.cTALink = appendMcicid(d1.cTALink, templateType, campaignUrl, referrerId, extraTacking);
            }
          });
        }
        if (itineraryDay2) {
          itineraryDay2.map((d2) => {
            if (d2 && d2.cTALink && d2.cTALink != '') {
              d2.cTALink = appendMcicid(d2.cTALink, templateType, campaignUrl, referrerId, extraTacking);
            }
          });
        }
        if (itineraryDay3) {
          itineraryDay3.map((d3) => {
            if (d3 && d3.cTALink && d3.cTALink != '') {
              d3.cTALink = appendMcicid(d3.cTALink, templateType, campaignUrl, referrerId, extraTacking);
            }
          });
        }
        if (itineraryDay4) {
          itineraryDay4.map((d4) => {
            if (d4 && d4.cTALink && d4.cTALink != '') {
              d4.cTALink = appendMcicid(d4.cTALink, templateType, campaignUrl, referrerId, extraTacking);
            }
          });
        }
        if (itineraryDay5) {
          itineraryDay5.map((d5) => {
            if (d5 && d5.cTALink && d5.cTALink != '') {
              d5.cTALink = appendMcicid(d5.cTALink, templateType, campaignUrl, referrerId, extraTacking);
            }
          });
        }
      });
    }

    if (checkValue(conciergePageData?.wizardUrlOverride)) {
      conciergePageData?.wizardUrlOverride?.map((wo) => {
        if (checkValue(wo?.searchUrlOverride)) {
          wo.searchUrlOverride = appendMcicid(
            wo?.searchUrlOverride,
            templateType,
            campaignUrl,
            referrerId,
            extraTacking
          );
        }
      });
    }

    // Explore items group
    conciergePageData.categoryItems = categoryItems;
    conciergePageData.categoryFilters = categoryFilters;
    conciergePageData.filterType = filterType;

    return conciergePageData;
  }

  private setGlobalMcicds(storeData) {
    const templateType = storeData?.templateType;
    const campaignUrl = templateType != 'cplite' ? storeData?.campaignUrl : storeData?.mainUrl;
    const referrerId = storeData?.referrerId;
    const mainUrl = storeData?.mainUrl;
    const extraTacking = storeData?.extraTrackingParam;

    if (checkValue(storeData.partnerHeaderNavigation)) {
      for (let i = 0; i < storeData.partnerHeaderNavigation.length; i++) {
        const partnerNavItem = storeData.partnerHeaderNavigation[i];
        if (checkValue(partnerNavItem.navigationItemUrl) && partnerNavItem.navigationItemUrl != '') {
          partnerNavItem.navigationItemUrl = appendMcicid(
            partnerNavItem.navigationItemUrl,
            templateType,
            campaignUrl,
            mainUrl,
            referrerId,
            extraTacking
          );
          storeData.partnerHeaderNavigation[i].navigationItemUrl = partnerNavItem.navigationItemUrl;
        }
      }
    } else {
      if (checkValue(storeData.partnerHeaderNavigationItems)) {
        for (let i = 0; i < storeData.partnerHeaderNavigationItems.length; i++) {
          const partnerNavItem = storeData.partnerHeaderNavigationItems[i];
          if (checkValue(partnerNavItem.navigationItemUrl) && partnerNavItem.navigationItemUrl != '') {
            partnerNavItem.navigationItemUrl = appendMcicid(
              partnerNavItem.navigationItemUrl,
              templateType,
              campaignUrl,
              mainUrl,
              referrerId,
              extraTacking
            );
            storeData.partnerHeaderNavigationItems[i].navigationItemUrl = partnerNavItem.navigationItemUrl;
          }
        }
      }
    }

    if (checkValue(storeData.content)) {
      for (let i = 0; i < storeData.content.length; i++) {
        const content = storeData.content[i];
        if (checkValue(content.sponsorBannerCtaUrl)) {
          content.sponsorBannerCtaUrl = appendMcicid(
            content.sponsorBannerCtaUrl,
            templateType,
            campaignUrl,
            mainUrl,
            referrerId,
            extraTacking
          );
        }
      }
    }

    return storeData;
  }

  @action
  public setConciergeFilters = async (filter: Array<filterPops>): Promise<any> => {
    this.conciergePageData.filters = filter;
  };

  @action
  public removeConciergeFilters = async (): Promise<any> => {
    this.conciergePageData.filters = undefined;
  };

  private setCpCmsLiteMultipleHotelDeals(cpCmsLitePageData) {
    const multiHotels = [];
    let multiHotelsTitle = '';
    let firstHotelModuleIndex = 0;
    if (checkValue(cpCmsLitePageData?.content)) {
      for (let i = 0; i < cpCmsLitePageData?.content?.length; i++) {
        const content = cpCmsLitePageData?.content?.[i];
        if (content?.module === 'hotelModule') {
          multiHotels?.push(content);
          if (multiHotels?.length < 2) {
            multiHotelsTitle = content?.title;
            firstHotelModuleIndex = i;
          }
        }
      }

      if (multiHotels.length > 0) {
        const multiHotelsModule = {
          title: multiHotelsTitle,
          module: 'multiHotelsModule',
          hotels: multiHotels,
        };

        // Push multiHotel Module to the first hotel module position
        cpCmsLitePageData?.content?.splice(firstHotelModuleIndex, 0, multiHotelsModule);

        // Delete all hotel modules from the cpCmsLitePageData
        const updatedContent = cpCmsLitePageData?.content?.filter((m) => m.module !== 'hotelModule');
        cpCmsLitePageData.content = updatedContent;
      }
    }

    return cpCmsLitePageData;
  }

  private setMediaData(cpCmsLitePageData, templateName, partner) {
    const largeModules = cpCmsLitePageData.content.filter((p) => p.module === 'largeImageTileModule');
    const navigation = [];
    if (largeModules.length > 0) {
      const templateType = cpCmsLitePageData.templateType;
      const campaignUrl = cpCmsLitePageData.mainUrl;
      const referrerId = cpCmsLitePageData.referrerId;

      for (let i = 0; i < largeModules.length; i++) {
        const lmItem = largeModules[i];
        let videoPageUrl = '';
        if (checkValue(lmItem?.mediaList, true)) {
          for (let j = 0; j < lmItem?.mediaList?.length; j++) {
            const videoId =
              lmItem?.urlFriendlyName + '-' + lmItem?.mediaList[j]?.uniquekey?.replace('mediaList-', 'vid');
            videoPageUrl = 'video/' + videoId.toLowerCase();

            const mediaUrl = appendMcicid(
              `/${partner}/${templateName}/${videoPageUrl}`,
              templateType,
              campaignUrl,
              referrerId,
              this.extraTrackingParam
            );
            // video url format media-studio/{tempname}/video/{channel-vid{id}}
            // /media-studio/mstudiodev/video/panama-vid1
            lmItem.mediaList[j].url = mediaUrl;
          }
        }
        const url =
          lmItem?.mediaList?.length > 1
            ? 'channel/' + lmItem?.urlFriendlyName?.trim().replace(/ /g, '-').toLowerCase()
            : videoPageUrl;
        const mainUrl = `/${templateName}/${url}`;
        const navigationUrl = appendMcicid(mainUrl, templateType, campaignUrl, referrerId, this.extraTrackingParam);
        // Set Main Url of subpages
        lmItem.mainUrl = `/${partner}${navigationUrl}`;
        navigation.push({
          title: lmItem?.heroTitleDesktop,
          urlFriendlyName: lmItem?.urlFriendlyName,
          url: navigationUrl,
        });
      }
    }
    cpCmsLitePageData.msdata = {
      navigation,
      subPages: largeModules,
    };
  }

  // This is sample global hero object
  private setGlobalHero(storeData, template) {
    if (checkValue(storeData)) {
      const content = get(storeData, `${template === 'spotlight' ? 'pageContent' : 'content'}`, []);
      const { moduleName, heroName } = this.getHeroModuleName(template, content);
      const heroModule = content.filter((p) => p?.module === moduleName);
      const heroIndex = content.map((p) => p?.module).indexOf(moduleName);
      if (checkValue(heroModule, true)) {
        const heroModuleData =
          template === 'spotlight'
            ? spotlightDataMap(heroModule?.[0], storeData)
            : accelerateDataMap(heroModule?.[0], storeData);

        const heroSubModules = setGlobalHeroData(heroModuleData);
        if (checkValue(heroSubModules, true)) {
          const heroData = {
            module: heroName,
            isColorBars: checkValue(heroModuleData?.barColorLeft) || checkValue(heroModuleData?.barColorRight),
            isOverlayImage: checkValue(heroModuleData?.heroOverlayImageDesktop, true),
            components: heroSubModules,
            //heroData: { ...heroModuleData },
            heroData: {
              ...heroModuleData,
              mccidData: {
                templateType: storeData?.templateType,
                campaignUrl: storeData?.mainUrl,
                //mainUrl:storeData?.mainUrl,
                referrerId: storeData?.referrerId,
              },
            },
          };
          if (template === 'spotlight') {
            storeData?.pageContent?.splice(heroIndex, 1);
            storeData?.pageContent?.unshift(heroData);
          } else {
            storeData?.content?.splice(heroIndex, 1);
            storeData?.content?.unshift(heroData);
          }
        }
      }
    }
    return storeData;
  }

  //get the correct hero module name from content
  private getHeroModuleName(template, content) {
    let moduleName = 'heroModule';
    let heroName = 'globalHero';
    if (template === 'spotlight') {
      const isHeroV2 = content.filter((data) => data?.module === 'v2HeroModuleSpotlight');
      if (checkValue(isHeroV2, true)) {
        moduleName = 'v2HeroModuleSpotlight';
        heroName = 'v2GlobalHero';
      } else {
        moduleName = 'heroModuleSpotlight';
      }
    } else {
      const isHeroV2 = content.filter((data) => data?.module === 'v2HeroModule');
      if (checkValue(isHeroV2, true)) {
        moduleName = 'v2HeroModule';
        heroName = 'v2GlobalHero';
      }
    }
    return { moduleName, heroName };
  }

  private setLozengeMessage(storeData) {
    if (checkValue(storeData) && checkValue(storeData.lozengeMessage)) {
      storeData.lozengeDetailMessage = storeData.lozengeMessage;
    }
    return storeData;
  }

  // randomizing the article data
  private setRandomizedArticleData(storeData, template) {
    if (checkValue(storeData)) {
      const content = get(storeData, `${template === 'spotlight' ? 'pageContent' : 'content'}`, []);
      const artileModule = content.filter((data) => data?.module === 'v2FeaturedArticleModule');
      const articleIndex = content.map((p) => p?.module).indexOf('v2FeaturedArticleModule');

      // randomize
      if (checkValue(artileModule, true)) {
        const randomizedList =
          artileModule[0]?.randomArticle === 'True'
            ? randmolyShuffledArray(artileModule[0].articleList)
            : artileModule[0]?.articleList;
        content[articleIndex] = { ...artileModule[0], articleList: [...randomizedList] };

        const artilePanelModule = content.filter((data) => data?.module === 'v2ArticlePanelModule');
        const panelIndex = content.map((p) => p?.module).indexOf('v2ArticlePanelModule');

        const articleItemList = [...randomizedList];
        articleItemList.shift();
        if (checkValue(artilePanelModule, true) && artilePanelModule[0].linkToFeaturedArticleModule === 'True') {
          content[panelIndex] = { ...artilePanelModule[0], articleItemList: [...articleItemList] };
        }
        if (template === 'spotlight') {
          storeData.pageContent = content;
        } else {
          storeData.content = content;
        }
      }
    }
    return storeData;
  }

  @action
  public setStopoverSearchData = async (data: any): Promise<any> => {
    this.stopoverSearchData = data;
  };

  @action
  public setPageModuleVersion = async (storeData): Promise<any> => {
    const templateCampaignName = storeData?.templateCampaignName;
    const templateVersion = templateMachetes(templateCampaignName);

    if (checkValue(templateVersion)) {
      this.pageModuleVersion = templateVersion;
      return storeData;
    }

    let moduleVersion = 'V1';
   
    if (checkValue(storeData)) {
      const content = storeData?.content || storeData?.pageContent;

      if (checkValue(content, true)) {
        //const moduleName = content[0]?.module?.toLocaleLowerCase();
        const hasV3Modules = content.filter((f) => f.module.toLocaleLowerCase().startsWith('v3'));

        if (checkValue(hasV3Modules, true)) {
          moduleVersion = 'V3';

          // Set the template to v3-template
          // From here it set to v3template and will be load v3 template
          this.setName('v3template');
        } else {
          const hasV2Modules = content.filter((f) => f.module.toLocaleLowerCase().startsWith('v2'));
          if (checkValue(hasV2Modules, true)) {
            moduleVersion = 'V2';
          }
        }

        // if (moduleName?.includes('v3')) {
        //   moduleVersion = 'V3';

        //   // Set the template to v3-template
        //   // From here it set to v3template and will be load v3 template
        //   this.setName('v3template');

        //   storeData.content = content;
        // } else if (moduleName?.includes('v2')) {
        //   moduleVersion = 'V2';
        // }
      }
    }

    this.pageModuleVersion = moduleVersion;
    return storeData;
  };
}
