import React from 'react';
import { css } from 'styled-components';

//compoenents
import Layout from 'src/components/common/theme/Layout';

//helpers
import { checkValue } from 'src/components/common/helper';

export interface ModuleColorBarProps {
  leftRibbonColor?: string;
  middleRibbonColor?: string;
  rightRibbonColor?: string;
}

const ModuleColorBar: React.FC<ModuleColorBarProps> = ({ leftRibbonColor, middleRibbonColor, rightRibbonColor }) => {
  const moduleCss = () => {
    return `
            &.module-color-bar-ribbon{
                .ribbon-one{
                    background-color: ${checkValue(leftRibbonColor) ? leftRibbonColor : 'transparent'} !important;
                }
                .ribbon-two{
                    background-color: ${checkValue(middleRibbonColor) ? middleRibbonColor : 'transparent'} !important;
                }
                .ribbon-three{
                    background-color: ${checkValue(rightRibbonColor) ? rightRibbonColor : 'transparent'} !important;
                }
            }
        `;
  };

  if (!checkValue(leftRibbonColor) && !checkValue(middleRibbonColor) && !checkValue(rightRibbonColor)) {
    return null;
  }

  return (
    <Layout
      css={css`
        ${moduleCss()}
      `}
      HtmlTag="section"
      className="module-color-bar-ribbon"      
    >
      <div className="color-bar-ribbon ribbon-one" />
      <div className="color-bar-ribbon ribbon-two" />
      <div className="color-bar-ribbon ribbon-three" />
    </Layout>
  );
};

export default ModuleColorBar;
