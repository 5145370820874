import React from 'react';
import { checkValue } from 'src/components/common/helper';
import { getDeviceImage } from 'src/components/common/theme/component';
import { StickerProps } from 'src/components/common/module-util';

export const Sticker = (props: StickerProps) => {
  const { stickerImage = '', stickerPosition = '', exClass = '' } = props;

  const stickerImg = checkValue(stickerImage) ? getDeviceImage(stickerImage, 'desktop') : '';

  return checkValue(stickerImg) ? (
    <div className={`sticker-container ${exClass}`}>
      <img
        className={`sticker ${checkValue(stickerPosition) ? stickerPosition : 'top-left'}`}
        src={stickerImg}
        alt="module-sticker"
      />
    </div>
  ) : null;
};
