import { appendMcicid, checkValue } from "src/components/common/helper";
import { get } from 'lodash';

export const accelerateDataMap = (data, storeData) => {
  return {
    ...data,
    turnOffBreadcrumb: true,
    breadcrumbCurrentCopy: "",
    breadcrumbHomeCopy: "",
    breadcrumbParentCopy: "",
    breadcrumbParentUrl: "",
    isMiddleLayerEnabled: "false",
    breadcrumbHomeUrl: "",
    heroVideoPosition: checkValue(data?.heroVideoPosition) ? data?.heroVideoPosition : "Background video",
    heroSearchWizData:get(storeData, 'content', []).find(el => el.module.includes('bookingWidget')),
    wizardLocalization:get(storeData, 'wizardLocalization', []),
    customeProp:{
      template:"accelerate"
    }
  };
};

const setHeroStyle = (style) => {
  switch (style) {
    case 'Article Style 1':
      return 'Text-left';

    case 'Article Style 2':
      return 'Text-bottom-left';

    case 'Article Style 3':
      return 'Text-centered';

    default:
      return 'Text-left';
  }
};

export const spotlightDataMap = (heroData, storeData) => {  
  const templateType = storeData?.templateType;
  const campaignUrl = storeData?.campaignUrl;
  const referrerId = storeData?.referrerId;
  const mainUrl = storeData?.mainUrl; 

  const {
    textColor,
    heroStyle,
    rectangleBarColorLeft,
    rectangleBarColorRight,
    heroImageMobile,
    heroImageNew,
    heroVideo,
    heroVideoImage,
    heroVideoPosition,
    heroSubTextWidth,
    ...data
  } = heroData;
  return {
    ...data,
    textColorNew: textColor,
    heroStyle: setHeroStyle(heroStyle),
    heroImage: heroImageNew,
    backgroundPhotoMobile: heroImageMobile,
    barColorLeft: rectangleBarColorLeft,
    barColorRight: rectangleBarColorRight,
    backgroundVideoFile: heroVideo,
    backgroundPhotoStaticVideoImage: heroVideoImage,
    turnOffBreadcrumb: storeData?.turnOffBreadcrumb,
    breadcrumbCurrentCopy: storeData?.breadcrumbCurrentCopy,
    breadcrumbHomeCopy: storeData?.breadcrumbHomeCopy,
    breadcrumbParentCopy: storeData?.breadcrumbParentCopy,
    breadcrumbParentUrl: storeData?.breadcrumbParentUrl,
    isMiddleLayerEnabled: storeData?.isMiddleLayerEnabled,
    breadcrumbHomeUrl: checkValue(storeData?.breadcrumbHomeUrl) ? appendMcicid(
      storeData?.breadcrumbHomeUrl,
      templateType,
      campaignUrl,
      mainUrl,
      referrerId,
      storeData?.extraTrackingParam
    )?.trim() : "",
    heroVideoPosition: checkValue(heroVideoPosition) ? heroVideoPosition : "Foreground video",
    heroSearchWizData:get(storeData, 'pageContent', []).find(el => el.module.includes('bookingWidget')),
    wizardLocalization:get(storeData, 'wizardLocalization', []),
    heroSubTextWidth: checkValue(heroSubTextWidth) ? heroSubTextWidth : "100%",
    customeProp:{
      template:"spotlight"
    }
  };
};
