import { RequestInit as DOMRequestInit } from 'graphql-request/dist/types.dom';

// We want to eventually load this from config with the current git hash and environment
const CLIENT_INFO = 'meso-cp-microsites-3,unknown,unknown';

import { getSdk, LodgingPwaPropertySearchQuery, OperationResult } from 'src/common/__generated__/api';
import { GraphQLClient } from 'graphql-request';
import { Sha256 } from '@aws-crypto/sha256-browser';
import crossFetch from 'cross-fetch';
import { isAPQDisabledOnWeb } from './utils/bex-api-utils';

const sha256 = async (data: string) => {
  const hash = new Sha256();
  hash.update(data);
  const result = await hash.digest();
  const hashArray = Array.from(result);
  const digest = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

  return digest;
};

export const getBexApiSdk = (headers: HeadersInit, url = '/graphql') => {
  const sdkHeaders = { ...headers, 'Client-Info': CLIENT_INFO };
  const options: DOMRequestInit = {
    headers: new Headers(sdkHeaders),
    credentials: 'same-origin',
    cache: 'no-cache',
    fetch: isAPQDisabledOnWeb() ? crossFetch : fetchWithAPQConfig,
  };

  const client = new GraphQLClient(url, options);

  return getSdk(client);
};

// APQ is an acronym for Automatic Persisted Queries
const fetchWithAPQConfig = async (url: RequestInfo, options: RequestInit) => {
  const { body, headers = new Headers() } = options;

  if (!('x-enable-apq' in headers && headers['x-enable-apq'] === 'true')) {
    return crossFetch(url, options);
  }

  const requestBody = JSON.parse(body as string) as { query: string; operationName: string; variables: object; extensions?: object };
  const getSha256Hash = await sha256(requestBody.query);

  const requestBodyWithPersistedQuery = {
    ...requestBody,
    extensions: { ...requestBody.extensions, persistedQuery: { sha256Hash: getSha256Hash, version: 1 } },
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { query, ...requestBodyWithoutQuery } = requestBodyWithPersistedQuery;

  const results = await crossFetch(url, { ...options, body: JSON.stringify(requestBodyWithoutQuery) });

  // workaround node-fetch clone hang -----------------------
  const text = await results.text();

  const results2 = new Response(text, {
    status: results.status,
    statusText: results.statusText,
    headers: Object.fromEntries(results.headers.entries()),
  });
  // ----------------------------------------------------------

  const res = JSON.parse(text) as OperationResult<LodgingPwaPropertySearchQuery>;

  if (res?.errors?.find(error => error.message === 'PersistedQueryNotFound' || error.extensions?.code === 'PERSISTED_QUERY_NOT_FOUND')) {
    return crossFetch(url, { ...options, body: JSON.stringify(requestBodyWithPersistedQuery) });
  }

  return Promise.resolve(results2);
};
