/* eslint-disable @typescript-eslint/no-unused-vars */
import { codeSplit, Controller, FetchOptions, Result, FetchPageOptions } from 'bernie-core';
import { PageData } from 'bernie-http';
import { buildNameCorrectionPageData } from '../utils/name-correction-utils';

export class GcoNameCorrectionController implements Controller {
  public pageId = 'page.name-correction';
  public path = '/travelsupport/name-form';
  public routeName = 'name-correction';
  public bundles = [];
  public routeData = {
    pageName: 'page.name-correction'
  };
  public caseSensitive = true;
  /**
   * Components may be referred to directly
   *   `public component = BrandUsaPage;`
   *
   * Or, the preferred option is to create a code split point that will generate a new bundle.
   *   Use webpacks magic comments `webpackChunkName: "INSERT_FRIENDLY_NAME_HERE"`
   *   This will give it a friendly name for the name of the file.
   */ 
  public component = codeSplit(() =>
    import(/* webpackChunkName: "brand-usa-page" */ '../../../views/gco/name-correction/name-correction-form')
  );
  public exact = true;

  // tslint:disable-next-line:prefer-function-over-method
  public fetch(options: FetchOptions): Promise<Result> { 
    return Promise.resolve({});
  }

  // tslint:disable-next-line:prefer-function-over-method
  public async fetchPageData(options: FetchPageOptions): Promise<PageData> {    
    return Promise.resolve(buildNameCorrectionPageData()) as Promise<PageData>;
  }
}
