/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Styled, { css } from 'styled-components';

interface LayoutProps {
  HtmlTag?: 'div' | 'h1' | 'h2' | 'p' | 'section';
  children?: React.ReactNode;
  className?: string;
  css?: css;
  id?: string;
  moduleStyle?: string;
  cpdsTheme?:
    | 'light'
    | 'dark'
    | 'brand-expedia-light'
    | 'brand-expedia-dark'
    | 'brand-hotels-light'
    | 'brand-hotels-dark'
    | 'brand-vrbo-light'
    | 'brand-vrbo-dark'
    | 'meso-expedia-light'
    | 'meso-expedia-dark'
    | 'meso-hotels-light'
    | 'meso-hotels-dark'
    | 'meso-vrbo-light'
    | 'meso-vrbo-dark';
}

const Layout: React.FC<LayoutProps> = ({ className, id, children, HtmlTag = 'div', moduleStyle, cpdsTheme }) => {
  const T = `${HtmlTag}` as keyof JSX.IntrinsicElements;

  const themeAttribute = cpdsTheme ? { 'data-cpds-theme': cpdsTheme } : {};

  // if(moduleStyle){
  //   return <T className={className} id={id} {...themeAttribute}>{children}</T>;
  //   //const StyleWrapper = () => Styled.div`${moduleStyle}`;
  //   //const MasterStyleWrapper = StyleWrapper();
  //   //return <MasterStyleWrapper><T className={className} id={id}>{children}</T></MasterStyleWrapper>
  // }else{
  //   return <T className={className} id={id} {...themeAttribute}>{children}</T>;
  // }

  return (
    <T className={className} id={id} {...themeAttribute}>
      {children}
    </T>
  );
};

const styledLayout = Styled(Layout)`
  ${(props) => props.css}
`;

export default styledLayout;
