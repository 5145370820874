import { useLocalization } from '@shared-ui/localization-context';

import { de_de } from './localizations/de_de';
import { en_us } from './localizations/en_us';
import { es_co } from './localizations/es_co';
import { es_mx } from './localizations/es_mx';
import { es_us } from './localizations/es_us';
import { pt_br } from './localizations/pt_br';
import { checkValue } from 'src/components/functions';

export const commonLocalization = {
  en_us: {
    ...en_us,
  },
  es_mx: {
    ...es_mx,
  },
  es_us: {
    ...es_us,
  },
  pt_br: {
    ...pt_br,
  },
  es_co: {
    ...es_co,
  },
  de_de: {
    ...de_de,
  }
};

export const mesoLocalization = (key: string, fallbackValue: string, ...args: string[]) => {  
  const { formatText } = useLocalization(); 
  const locValue =  formatText(key, ...args);
  // Pass the arguments dynamically
  return  checkValue(locValue) ? locValue : fallbackValue;
}     