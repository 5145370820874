import React from 'react';
import { UitkFigure, UitkImage, UitkFigureAspectRatioType } from 'uitk-react-images';
import {
  CopyrightWithGradientComponent,
  CopyrightCameraProps,
} from 'src/components/common/modules/copyright-with-gradient/code-split/copyright-with-gradient-component';
import { checkValue } from 'src/components/common/helper';
import ModuleColorBar, { ModuleColorBarProps } from 'src/components/common/modules/module-color-bar/module-color-bar';
export interface FigureProps {
  src: string;
  exClass?: string;
  imgClass?: string;
  ratio?: UitkFigureAspectRatioType;
  imageFit?: 'cover' | 'contain';
  alt?: string;
  children?: React.ReactNode;
  blankDefault?: boolean;
  placeholderImage?: boolean;
  isGradientActive?: boolean;
  copyRightProps?: CopyrightCameraProps;
  roundcorner?: any;
  imageProps?: any;
  colorBarProps?: ModuleColorBarProps;
}

const Figure: React.FC<FigureProps> = (props) => {
  const {
    alt,
    children,
    src = '',
    exClass = '',
    imgClass = '',
    ratio = UitkFigureAspectRatioType.R16_9,
    imageFit = 'cover',
    blankDefault = true,
    placeholderImage = true,
    isGradientActive = true,
    roundcorner = true,
    imageProps = {},
    colorBarProps = null,
    ...rest
  } = props;


  const isColorBarsAvailable = checkValue(colorBarProps) && Object.keys(colorBarProps).some(el => checkValue(colorBarProps[el]));


  return (
    <UitkFigure
      className={`${exClass} ${isColorBarsAvailable ? 'has-module-color-bar figure-with-color-bars' : ''}`}
      {...rest}
      imageFit={imageFit}
      roundcorner={roundcorner}
      ratio={ratio}
    >
      {isColorBarsAvailable ? <ModuleColorBar {...colorBarProps} /> : null}
      {isGradientActive && <div className="gradient"></div>}
      <UitkImage
        className={imgClass}
        additionalProps={imageProps}
        alt={alt}
        src={src}
        placeholderImage={placeholderImage}
        blankDefault={blankDefault}
      />
      {checkValue(children) ? children : null}
      {checkValue(rest.copyRightProps) && <CopyrightWithGradientComponent {...rest.copyRightProps} />}
    </UitkFigure>
  );
};

export default Figure;
