import { GraphQLClient } from 'graphql-request';
import { RequestInit as DOMRequestInit } from 'graphql-request/dist/types.dom';
import { getSdk } from 'src/common/__generated__/sanity/types';
import { checkValue } from 'src/components/functions';
import { config } from 'src/config/config';

export interface CampaignParams {
  partner: string;
  locale: string;
  siteId: string;
  templateName?: string;
  feedName?: string;
  isCacheFeed: string;
  referrerId: string;
}

export const getSanityApiSdk = (url = config.sanityEndPoint, cache: RequestInit["cache"] = 'no-cache', headers?: HeadersInit) => {
  const sdkHeaders = {
    authorization: `Bearer ${config.sanityToken}`,
    ...(checkValue(headers) ? headers : {}),
  };
  const options: DOMRequestInit = {
    headers: new Headers(sdkHeaders),
    //credentials: 'same-origin',
    cache
  }; 
  const client = new GraphQLClient(url, options); 
  return getSdk(client);
};