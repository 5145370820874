import { checkValue } from "src/components/functions";

export interface pageDataProps{
    partner: string,
    templateName: string,
    feedName: string
}
export const getPageData = (props:pageDataProps) => {
    const {partner, templateName, feedName} = props; 
    const pageName = "Sports Destination Landing";
    return {
        navigationId: 'hotel',
        pageId: `${pageName},H,10`,
        pageName: pageName,
        clickstreamPageName: pageName,
        clickstreamPageNameDetailed: pageName + ' loaded',
    };
};