import * as React from 'react';
import Balancer from 'react-wrap-balancer';

type TextBalancerProps = {
  children?: React.ReactNode;
};

// preferNative: false, bcz text-wrap: balance not availble for safari
export const TextBalancer: React.FC<TextBalancerProps> = ({ children }) => {
  return (
    <div className="text-balancer-wrapper">
      <Balancer preferNative={false}>{<>{children}</>}</Balancer>
    </div>
  );
};
