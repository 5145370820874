import * as React from 'react';

import { CopyrightWithGradientMap } from './copyright-with-gradient-map';

export interface CopyrightCameraProps {
  copyrightText?: any;
  copyrightCation?: any;
  position?: 'tl' | 'tr' | 'br' | 'bl';
  iconSize?: 'sm' | 'md' | 'lg';
}
/**
 * Looks at the code-split copyright camera map and renders the desired ui component based on the name.
 */
export const CopyrightWithGradientComponent = (props: CopyrightCameraProps) => {
  const component = CopyrightWithGradientMap;

  if (!component) {
    return null;
  }

  return React.createElement(component as any, props);
};
