import { SystemEvent, SystemEventLevel } from 'bernie-logger';

/**
 * Format: BEX_API_${SPINNER_NAME}_${QUERY_NAME}_ERROR
 *
 * QUERY_NAME should be followed by FETCH only if it's a query.
 */
export const SOURCE_EVENTS = {
  MESO3_BEX_API_GLOBAL_HEADER_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'MESO3_BEX_API_GLOBAL_HEADER_FETCH_ERROR'),
  MESO3_BEX_API_MEMBER_WALLET_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'MESO3_BEX_API_MEMBER_WALLET_FETCH_ERROR'),
  MESO3_BEX_API_CAMPAIGN_RECOMMENDATION_WITH_OFFERS_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'MESO3_BEX_API_CAMPAIGN_RECOMMENDATION_WITH_OFFERS_FETCH_ERROR'),
  MESO3_BEX_API_CAMPAIGN_BY_ID_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'MESO3_BEX_API_CAMPAIGN_BY_ID_FETCH_ERROR'),
  MESO3_BEX_API_PROPERTY_FILTER_SEARCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'MESO3_BEX_API_PROPERTY_FILTER_SEARCH_ERROR'),
  MESO3_BEX_API_CUSTOMER_PROPERTY_SEARCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'MESO3_BEX_API_CUSTOMER_PROPERTY_SEARCH_ERROR'),
  MESO3_BEX_API_LODGING_SEARCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'MESO3_BEX_API_LODGING_SEARCH_ERROR'),
  MESO3_BEX_API_FLIGHT_SEARCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'MESO3_BEX_API_FLIGHT_SEARCH_ERROR'),
};

export const SANITY_SOURCE_EVENTS = {  
  SANITY_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'SANITY_FETCH_ERROR'),
}