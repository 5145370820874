import { Context } from 'bernie-context';
import { AnalyticsStore, PageStore } from 'bernie-plugin-mobx';
import { inject, IWrappedComponent } from "mobx-react";
import { BexApiStore } from "./bex-api-store";
import { SanityStore } from './cp-sanity-store';
import { CpCmsLiteStore } from './cp-cmslite-store';

export * from './bex-api-store';
export * from './cp-cmslite-store';
export * from './cp-sanity-store';

export interface StoresType {
    analytics: AnalyticsStore;
    context: Context,
    page: PageStore;
    bexapistore: BexApiStore;   
    cpcmslitefeed:CpCmsLiteStore,
    sanityStore: SanityStore;
}

/**
 * Use this instead of {@code @inject} so you will not have to provide stores in
 * tests or use the non-null assertion operator (!) and ensure strong typing.
 *
 * @example
 * interface ExampleProps { name: string; analytics: AnalyticsStore; }
 * const Example = withStores("analytics") = (props: ExampleProps) => {...}
 */
 export const withStores = <TStoreProps extends keyof StoresType>(...stores: TStoreProps[]) => <
 TProps extends Pick<StoresType, TStoreProps>
>(
 component: React.ComponentType<TProps>
) => {
 return inject(...stores)(component) as InjectedComponent<TStoreProps, TProps>;
};

type InjectedComponent<TStoreProps extends keyof StoresType, TProps extends Pick<StoresType, TStoreProps>> = React.FC<
  Omit<TProps, keyof Pick<StoresType, TStoreProps>> & Partial<Pick<StoresType, TStoreProps>>
> &
  IWrappedComponent<TProps>;